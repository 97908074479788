import Axios from "axios";
import { errorResponse } from "./../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "./../../../../utils/configurations";


export function qbMaint(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any
) {
    let questionsList = "", name='', id ='', assets='', allowEvenForWrongAnswers=true;
    Object.entries(record).map((item: any)=>{
        if(item[0].toString().includes('question') && item[0].toString().toLowerCase() !== 'questionbankname') {
            questionsList=(questionsList === "")?questionsList+item[1]:questionsList+"~^~"+item[1];
        }
        if(item[0].toLowerCase() === 'questionbankname') {
            name=item[1];
        }
        if(item[0].toLowerCase() === 'qbid') {
            id=item[1];
        }
        if(item[0].toLowerCase() === 'allowevenforwronganswers') {
            allowEvenForWrongAnswers=item[1];
        }
        if(item[0].toLowerCase() === 'assetsassigned') {
            //assets=item[1];
            assets="";
            item[1]?.map((device:any)=>{
                if(assets===""){
                assets=assets+device.value;
            }
                else {
                    assets= assets+","+device.value;
                }
            })
        }


    });
    let data ={
        qbId: id,
        questionBankName: name,
        assetsAssigned: assets,
        questions: questionsList,
        allowEvenForWrongAnswers: allowEvenForWrongAnswers
    }
    return async (dispatch: any) => {


        let url="/ui/api/questionBanks"
        Axios.post(
            appConfigs.server.URL + url,
            data,
            {
                responseType: "json",
                headers: {}
            }
        )
        .then(response => {
            let data = response.data.data.questionBank == null ? {} : response.data.data.questionBank;
            dispatch({ type: "QB_MAINT_SUCCESS", payload: {data: data} });
            if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
            }
        })
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
        }
}

export function qbDelete(
    me: any,
    callbackSuccessFn: any,
    callbackFailureFn: any,
    questionBankId:string


) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/questionBank/" + questionBankId,
            {}
        )
        .then(response => {
                if (callbackSuccessFn != null) {
                    callbackSuccessFn(response);
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFailureFn != null) {
                    callbackFailureFn(error);
                }
            }
        );
    };
}

export function getAssetsToAssignQuestionbank(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string,
    orgId: string,
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets-to-assign-questionbank?page=1&pageSize=9999", {
                responseType: "json",
                headers: {},
                params: {
                    orgId: orgId
                }
            }
        )
        .then(response => {
                let data = (response.data.data.orgAssets == null ||
                        (response.data.data.orgAssets.length || 0) === 0 ) ? [] : response.data.data.orgAssets;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgAssets);
                }
                // let devices:any=[]
                // data.map((tag:any)=>{
                //    devices.push(tag['mastertag']+"`"+tag['serialNumber']);
                // })
                //data = data.map((tag: any) => tag['mastertag']).filter((item: any, i: any, ar: any) => ar.indexOf(item) === i).sort();
    
                dispatch({ type: "GET_ASSETS_WITHOUT_QB_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}
