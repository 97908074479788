import React from "react";
import { Layout, Dropdown, Menu, Button, Avatar, Row, Col, message, Badge, Tooltip, Card} from "antd";
import Icon, {
    SettingOutlined,
    DownOutlined,
    UserOutlined,
    LogoutOutlined,
    CloseCircleOutlined,
    SwapOutlined,
    FormOutlined,
    AppstoreAddOutlined,
    RightOutlined,    
    WarningOutlined,
    BellOutlined
} from "@ant-design/icons";

import OrgSelector from "../pages/OrgSelector/OrgSelector";
import "./header.css";

import UserMaint from "../pages/Users/UserMaint";
import Terms from "../pages/Terms/Terms";
import { getDrawerWidth, reloadPage, refreshPage, loadingIndicatorStart } from "../utils/commonHelpers";

import { connect } from "react-redux";
import * as rootActions from "../actions";
import * as usersActions from "../pages/Users/actions";
import * as orgSelectorActions from "../pages/OrgSelector/actions";
import history from '../utils/history';

import { ReactComponent as filterRemove } from "../assets/images/filter-remove.svg";

import { withOktaAuth } from '@okta/okta-react';
import TimeOutHandler from "./TimeOutHandler";
import { appConfigs } from "../utils/configurations";

const { Header } = Layout;

interface Prop {
    supportModeSet: Function,
    supportModeUnSet: Function,
    getUser: Function,
    userMaintOpen: Function,
    userMaintClose: Function,
    orgSelectorOpen: Function,
    orgSelectorRemoveSelections: Function,
    orgSelectorIncludeSuborgs: Function,
    orgSelectorUserOrgSelectionGet: Function,
    fleetStatsVisibility: Function,
    notificationVisibility: Function,
    getOrgRoles: Function,
    supportMode: any,
    context: any,
    orgSelector: any,
    usersList: any,
    roleList: any,
    authService?: any,
    authClient?: any,
    permissions: any,
    stats: any,
    notifications: any,
    assetsList: any,
}

interface State {
    orgSelectionVisible: boolean,
}

class TopHeader extends React.Component<Prop, State> {

    constructor(props: any) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    state = {
        orgSelectionVisible: false
    };

    componentDidMount() {

        history.listen((location, action) => {
            if (this.props.usersList.userMaint.visible) {
                this.props.userMaintClose(this);
            }
        });        
    }

    async logout(e:any) {
        e.preventDefault();
        localStorage.removeItem("pathname");
        loadingIndicatorStart();
        //Clear out the Support Mode    
        sessionStorage.clear(); 
        //
        message.loading("Logging out...", 0);

        const me = this;
        setTimeout(function () {
            window.localStorage.setItem("logoutInProgress", "true");
            me.props.authService.logout(window.location.origin + "/login");
        }, 500);     
        
    }

    myProfile = (e: any) => {
        e.preventDefault();
        this.props.getUser(this, this.myProfileShow, null, this.props.context.user.userId);
        this.props.getOrgRoles(this,null,null,this.props.context.appContext.orgId);
    }

    myProfileShow = (record: any) => {
        this.props.userMaintOpen(this, "My Profile", getDrawerWidth(), record, "MyProfile");
    };

    orgSelectorOpen = () => {
        this.props.orgSelectorOpen(this, "Org Selector", getDrawerWidth());
    };

    orgSelectorRemoveSelections = () => {
        this.props.orgSelectorRemoveSelections(this, this.orgSelectorRemoveSelectionsSuccess);
    };

    orgSelectorRemoveSelectionsSuccess = () => {
        message.success("Org selections are successfully removed")
        refreshPage(history);
    };
    
    orgSelectorIncludeSuborgs = () => {
        this.props.orgSelectorIncludeSuborgs(this, this.orgSelectorIncludeSuborgsSuccess, null, !this.props.orgSelector.includeSuborgs);
    };

    orgSelectorIncludeSuborgsSuccess = () => {
        //message.success("Sub-Orgs setting has been successfully updated")
        refreshPage(history);
    };

    supportModeUnSet = (e: any) => {
        this.props.supportModeUnSet(this, this.supportModeUnSetSuccess);
    }

    supportModeUnSetSuccess = () => {
        loadingIndicatorStart();
        setTimeout(function () {
            window.location.href = "/map"; 
        }, 500); 
        
        // setTimeout(function () {
        //     reloadPage();
        // }, 250);             
    }


    render() {

        const { user:contextUser, termsAndConditions, initialized:contextInitialized } = this.props.context;
        const href = window.location.href.toLowerCase();
        let showBanner = true;
        if (href.includes(appConfigs.server.claytonServerURL.toLowerCase()) ||
            href.includes(appConfigs.server.hoistServerURL.toLowerCase()) ||
            href.includes(appConfigs.server.toyotaServerURL.toLowerCase())) 
        {
            showBanner = false;
        }     
        const userMenu = (
            <Menu>           
                {
                    (this.props.supportMode.orgId || "") === "" &&
                    <Menu.Item data-id="header-org-selector-user-menu-my-profile" key="1">
                        <a href="# " onClick={(e) => { this.myProfile(e) }}>
                            <UserOutlined className="pr-5"/>
                            My Profile
                        </a>
                    </Menu.Item>
                }                
                {
                    (this.props.supportMode.orgId || "") !== "" &&
                    <Menu.Item data-id="header-org-selector-user-menu-exit-support-mode" key="1">
                        <a href="# " onClick={(e) => { this.supportModeUnSet(e) }}>
                            <CloseCircleOutlined className="pr-5"/>
                            Exit Support Mode
                        </a>
                    </Menu.Item>
                }                
                <Menu.Divider data-id="header-org-selector-user-menu-divider"/>           
                <Menu.Item data-id="header-org-selector-user-menu-logout" key="2">
                    <a href="# " onClick={(e) => { this.logout(e) }}>
                        <LogoutOutlined className="pr-5"/>
                        Logout 
                    </a>
                </Menu.Item>
            </Menu>
        );

        const orgSelectorMenu = (
            <Menu>
                <Menu.Item data-id="header-org-selector-button-remove-all" key="1" icon={<CloseCircleOutlined />} onClick={this.orgSelectorRemoveSelections}>
                    Remove All
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item data-id="header-org-selector-button-include-suborgs" key="2" icon={<AppstoreAddOutlined />} onClick={this.orgSelectorIncludeSuborgs}>
                    {this.props.orgSelector.includeSuborgs ? "Exclude" : "Include"} Sub-Orgs
                </Menu.Item>
                <Menu.Item data-id="header-org-selector-button-open" key="3" icon={<FormOutlined />} onClick={this.orgSelectorOpen}>
                    Open
                </Menu.Item>
            </Menu>
        );



        return (
            <>
                <Header id="header" className="site-layout-background">
                    <Row>
                        <Col flex={2} >

                            <div className="global-header">
                                <div className="header-left-content">
                                    
                                    {
                                        (contextInitialized || false) &&
                                        this.props.orgSelector.orgSelection.length > 0 &&
                                        <Dropdown.Button data-id="header-org-selector-button" type="ghost" onClick={this.orgSelectorOpen} overlay={orgSelectorMenu} className="header-org-selector-big">
                                            <span><SettingOutlined className="pr-5"/>Org Selector</span>
                                            {
                                                this.props.orgSelector.orgSelection.length > 0 &&
                                                <>
                                                    <span className="pr-5"></span>
                                                    <Badge data-id="header-org-selector-badge_count" className={"site-badge-count-4" + (this.props.orgSelector.includeSuborgs ? " org-selector-has-sub-orgs" : "")} count={this.props.orgSelector.orgSelection.length} style={{ backgroundColor: '#108ee9', marginBottom: 4 }} />
                                                </>
                                            }
                                        </Dropdown.Button>
                                    }

                                    
                                    {
                                        (contextInitialized || false) &&
                                        this.props.orgSelector.orgSelection.length === 0 &&
                                        <Button data-id="header-org-selector-button" type="ghost" block icon={<SettingOutlined />} onClick={this.orgSelectorOpen} className="header-org-selector-big">
                                            <span>Org Selector</span><span>{this.props.orgSelector.orgSelection.length > 0 ? " (" + this.props.orgSelector.orgSelection.length + ")" : ""}</span>
                                        </Button>
                                    }

                                    
                                    
                                    {
                                        (contextInitialized || false) &&
                                        <Button data-id="header-org-selector-button" type="ghost" icon={<SettingOutlined />} onClick={this.orgSelectorOpen} className="header-org-selector-small">
                                            {
                                                this.props.orgSelector.orgSelection.length > 0 &&
                                                <>
                                                    <span className="pr-5"></span>
                                                    <Badge data-id="header-org-selector-badge-sub-orgs" className={"site-badge-count-4" + (this.props.orgSelector.includeSuborgs ? " org-selector-has-sub-orgs" : "")} count={this.props.orgSelector.orgSelection.length} style={{ backgroundColor: '#108ee9', marginBottom: 4 }} />
                                                </>
                                            }
                                        </Button>
                                    }


                                    
                                    { this.props.orgSelector.orgSelection.length > 0 &&
                                        <>
                                            <Button className='header-stats-big' loading={ this.props.stats.loading } onClick={() => this.props.fleetStatsVisibility(!this.props.stats.visible)} style={{ marginLeft: 5, backgroundColor: this.props.stats.visible ? "#33b5e5" : "", color: this.props.stats.visible ? "white" : "", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))" }} type="ghost">
                                                <span className='btn-flOv'>Fleet Overview</span>
                                            </Button>
                                            
                                            {
                                                this.props.context.user.contextUserIsAdmin && this.props.context.appContext.orgId == 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' && 
                                                <Badge count={this.props.stats.data.expiredGateways > 0 ? this.props.stats.data.expiringSoon > 0 ? 2 : 0 : 1} >
                                                    <Button className='header-notifications-big' loading={this.props.stats.loading} onClick={() => this.props.notificationVisibility(!this.props.stats.visible)} style={{ marginLeft: 5, backgroundColor: this.props.notifications.visible ? "#33b5e5" : "#e5a733", color: this.props.notifications.visible ? "white" : "", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))" }} type="ghost">
                                                            <BellOutlined className="wiggle btn-notification" style={{ fontSize: "19px", color: 'white' }} />
                                                    </Button>
                                                </Badge>
                                            }

                                            
                                            <Button className='header-stats-small' loading={ this.props.stats.loading } onClick={() => this.props.fleetStatsVisibility(this.props.stats.visible)} style={{backgroundColor: this.props.stats.visible ? "#33b5e5" : "", color: this.props.stats.visible ? "white" : "", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))" }} icon={<Icon className="btn-flOv" component={filterRemove} style={{fontSize: 20 }} />}/>
                                        </>
                                    }

                                    


                                    

                                    <OrgSelector />
                                    <UserMaint />
                                    <Terms 
                                        visible={termsAndConditions !== null && termsAndConditions.hasOwnProperty("text") && (termsAndConditions.text || "") !== ""} 
                                        text={termsAndConditions !== null ? termsAndConditions.text : ""}
                                    />
                                </div>
                                <div>
                                <TimeOutHandler/>
                                </div>
                                
                            </div>
                            
                        </Col>

                        {
                           showBanner ? (
                            <> <Col flex={100}>
                                { true &&
                                    <>
                                        <div style={{ color: 'red', fontWeight: "bold", textAlign: "center" }} className="blink">
                                            <WarningOutlined className="pr-1"/>

                                            <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: 15, }}>The new URL <a style={{ color: 'red', textDecoration: 'underline' }} href="https://mobileiot.parker.com" target="_blank" rel="noopener noreferrer">mobileiot.parker.com</a> will be live starting 01/30/2025.</span> 
                                        </div>
                                    </>
                                }
                            </Col></>):""
                        }

                        {/*{<Col flex={100}>
                                { true &&
                                    <>
                                        <Tooltip title="AT&T network is down nationwide in some areas. Some assets may not be accessible due to the AT&T outage."> 
                                            <div className="danger-banner show-banner">
                                                <WarningOutlined className="pr-1"/>
                                                <span style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: 15}}> AT&T network is down nationwide in some areas. Some assets may not be accessible due to the AT&T outage.</span>
                                            </div>
                                        </Tooltip>
                                    </>
                                }
                        </Col> }*/}

                        <Col flex={2}>

                            <div className="header-right-content">
                                <span style={{ float: 'right' }} className="header-user padding-left-15px">
                                    {/*
                                            OPS-968     
                                            contextUser !== null && 
                                            (contextUser.avatarUploadSrc || "") !== "" && 
                                            <>
                                                {
                                                    (this.props.supportMode.orgId || "") === "" &&
                                                    <a href="# " onClick={(e) => { this.myProfile(e) }} data-id="header-avatar">
                                                        <Avatar src={appConfigs.server.URL + contextUser.avatarUploadSrc} size="small" />
                                                    </a>
                                                }   
                                                {
                                                    (this.props.supportMode.orgId || "") !== "" &&
                                                    <Avatar src={appConfigs.server.URL + contextUser.avatarUploadSrc} size="small" />
                                                }   
                                            </>
                                        */}
                                    {
                                        contextUser !== null && (contextUser.fullName || "") !== "" &&
                                        <>
                                            <a href="# " onClick={(e) => { this.myProfile(e) }} data-id="header-avatar">
                                                <Avatar size="small" icon={<UserOutlined />} />
                                            </a>
                                            <Dropdown data-id="header-user-menu" className="header-name" overlay={userMenu} trigger={['click']} placement="bottomRight">
                                                <a href="# " className="ant-dropdown-link">
                                                    <span className="header-user-full-name">{contextUser.fullName}</span><DownOutlined />
                                                </a>
                                            </Dropdown>
                                        </>
                                    }
                                </span>
                            </div>

                        </Col>

                        
                    </Row>  
                </Header>
                {
                    (this.props.supportMode.orgId || "") !== "" &&
                    <Header className="header-support">
                        <Row>
                            <Col xs={2} md={6}>                        
                                <strong>
                                    <RightOutlined className="pr-5"/>
                                    <span className="support-mode-small">SUPPORT MODE</span>
                                </strong>
                            </Col>
                            <Col xs={22} md={18} style={{ textAlign: "right"}}>
                                <Tooltip title="Exit Support Mode"> 
                                    <Button
                                        type="link"
                                        icon={<CloseCircleOutlined />}
                                        onClick={(e) => { this.supportModeUnSet(e) }}>
                                        <span>{ this.props.supportMode.orgName }</span>
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Header>
                }
                {  /*                 
                    <Header className="site-layout-background">
                        <Row>
                        {<Col flex={100}>
                                { true &&
                                    <>
                                        <Tooltip title="We are aware of the SMS notification issues, and actively working with our SMS provider on this matter. We hope to get this resolved soon."> 
                                            <div className="danger-banner show-banner">
                                                <WarningOutlined className="pr-2"/> 
                                                <span style={{fontSize: 13}}> We are aware of the SMS notification issues, and actively working with our SMS provider on this matter. We hope to get this resolved soon.</span>
                                            </div>
                                        </Tooltip>
                                    </>
                                }
                        </Col> }                           
                        </Row>
                    </Header>
                            */}
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        supportMode: state.supportModeReducer,
        context: state.contextReducer.context,
        orgSelector: state.orgSelectorReducers.orgSelectorState.orgSelector,
        usersList: state.usersReducers.usersState.usersList,
        permissions: state.contextReducer.data,
        roleList: state.usersReducers.usersState.roleList,
        stats: state.contextReducer.stats,
        notifications: state.contextReducer.notifications,
        assetsList: state.geofencesReducer.geofencesState.geofencesList.geoFenceMaint.assetsList
    };
};

export default connect(
    mapStateToProps,
    { 
        ...rootActions,
        ...usersActions, 
        ...orgSelectorActions 
    }
)(withOktaAuth(TopHeader));
