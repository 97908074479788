import React from "react";
import { Form, Select, Card, Input } from 'antd';

import { appConfigs } from "../../../utils/configurations";

import { connect } from "react-redux";
import * as actions from "../actions";
import { initCap, getPermissions } from "../../../utils/commonHelpers";

const { Option } = Select;

interface Prop {
    administeredOrgsGet: Function,
    getOrgRoles:Function,
    getOrgUserRoles:Function,
    roleList: any,
    usersList: any,
    context: any,
    formRef: any,
    permissions: any,
    iscreate: boolean,
    isupdate: boolean,
    isread: boolean
}
interface State {
    orgId: string,
    roleName: string
}

class UserHomeOrg extends React.Component<Prop, {}> {
    [x: string]: any;

    state: State = {
        orgId: "",
        roleName: ""
    };
    
    componentDidMount() {
        if((this.props.context.user.contextUserIsAdmin || false) === true){
            this.props.getOrgRoles(this,null,null,this.props.context.appContext.orgId);
        }

        this.setState({roleName: this.props.usersList.userMaint.record.orgHomeRole});
        this.componentUpdate();
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.usersList.userMaint.instance !== prevProps.usersList.userMaint.instance) {
            this.setState({roleName: ""});
            this.componentUpdate();
        }
    }

    componentUpdate() {
        const me = this;
        setTimeout(function () {
            me.componentUpdateDo(me.props.formRef!.getFieldValue("orgIdHome"));
            me.componentUpdateDo(me.props.formRef!.getFieldValue("orgHomeRole"));
        }, 150); 
    }

    componentUpdateDo(orgIdHome: string) {
       
        if ((this.props.usersList.userMaint.administeredOrgs.length || 0) === 0) {        
            this.props.administeredOrgsGet(this, null, null, null, null);
        }

        if((this.props.roleList.roleMaint.orgRoles || 0) === 0 && (this.props.context.user.contextUserIsAdmin || false) === true){
            const { appContext } = this.props.context;
            this.props.getOrgRoles(this,null,null,appContext.orgId);
        }

        if((!this.props.context.user.contextUserIsAdmin) && this.props.usersList.userMaint.pageName !== "MyProfile"){
            this.props.getOrgUserRoles(null, null, null, this.props.context.appContext.orgId, this.props.context.appContext.roleName, null);
        }
        
    }

    onOrgIdChange = (value: string) => {       
        this.setState({ orgId: value });        
        if(this.props.context.user.contextUserIsAdmin === true){
            this.props.getOrgRoles(this,null,null,value);
        }
        else{
            this.props.getOrgUserRoles(null, null, null, value, this.props.context.appContext.roleName, null);
        }
        
        //this.props.formRef.setFieldsValue({orgHomeRole: ""});
        this.props.formRef.setFieldsValue({orgHomeRole: this.props.context.appContext.roleName});
    }
    
    render() {
    
        const { user:contextUser, appContext } = this.props.context;        
        const { administeredOrgs } = this.props.usersList.userMaint;
        const { record } = this.props.usersList.userMaint;
        const { roles } = this.props.roleList.roleMaint.orgRoles.data;
        
        const isRoleAdmin = record.role === "admin"; 
        const isSameUser=(record.userId===appContext.userId)
        const isEnabled = isRoleAdmin || contextUser.contextUserIsAdmin ||!isSameUser;
        return (
            <Card 
                data-id="users-maint-info-home-org"
                size="small" 
                title="Home Organization" 
            >
                {
                    //ops-2784, ops-2823
                    // ( record.objectAdmin || (contextUser.contextUserIsAdmin && (record.userId || "") === "")) &&
                    
                    (
                        (administeredOrgs.length || 0) !== 0 && (this.props.usersList.userMaint.pageName !== "MyProfile")
                    ) &&
                    <Form.Item
                        name="orgIdHome"
                        label="Name"
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}

                    >                    
                        <Select 
                            data-id="users-maint-info-home-org-id"
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select..."
                            optionFilterProp="children"
                            className="select-before"
                            onChange={this.onOrgIdChange}                             
                        >
                            {administeredOrgs !== null && administeredOrgs.map((record:any, index:number) => (
                                <Option key={record.orgId} value={record.orgId}>
                                    {record.name}
                                </Option>
                            ))}
                        </Select>                         
                    </Form.Item>
                }
                {
                    //ops-2784 ; ops-2823                  
                    (
                        (administeredOrgs.length || 0) !== 0 && (this.props.usersList.userMaint.pageName === "MyProfile") && contextUser.contextUserIsAdmin
                    ) &&
                    <Form.Item
                        name="orgIdHome"
                        label="Name"
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}

                    >                    
                        <Select 
                            data-id="users-maint-info-home-org-id"
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select..."
                            optionFilterProp="children"
                            className="select-before"
                            onChange={this.onOrgIdChange}                             
                        >
                            {administeredOrgs !== null && administeredOrgs.map((record:any, index:number) => (
                                <Option key={record.orgId} value={record.orgId}>
                                    {record.name}
                                </Option>
                            ))}
                        </Select>                         
                    </Form.Item>
                }
                {
                    //ops-2784;  ops-2823
                    // !( record.objectAdmin || (contextUser.contextUserIsAdmin && (record.userId || "") === "")) &&

                    (
                        (record.length || 0) !== 0 &&  (administeredOrgs.length || 0) === 0
                        ||((this.props.usersList.userMaint.pageName === "MyProfile") && !contextUser.contextUserIsAdmin)
                    ) &&
                    <>
                        <Form.Item 
                            name="orgIdHome"
                            style={{ display: 'none' }}
                        >
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.Item
                            label="Name"
                        >
                            <span>{record.orgHomeName}</span>
                        </Form.Item>
                    </>
                }

                {/* ops-2784;  ops-2823 */}
                {
                    ( (record.length || 0) === 0 && (administeredOrgs.length || 0) === 0 && (this.props.usersList.userMaint.pageName !== "MyProfile")
                    ) &&

                    <>
                        <Form.Item 
                            name="orgIdHome"
                            style={{ display: 'none' }}
                        >
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.Item
                            label="Name"
                        >
                            <span>{contextUser.homeOrg.name}</span>
                        </Form.Item>
                    </>
                }
                {
                    // ops-2784; ops-2823
                    // ( record.objectAdmin || (contextUser.contextUserIsAdmin && (record.userId || "") === "")) &&
                   // (contextUser.contextUserIsAdmin && (record.userId || "") === "")?
                   (this.props.usersList.userMaint.pageName !== "MyProfile" && (roles.length || 0) !== 0) &&
                    <Form.Item
                        name="orgHomeRole"
                        label="eRole"
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                    <Select
                            data-id="users-maint-info-home-org-role"
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select..."
                            optionFilterProp="children"
                            className="select-before"
                            disabled={!isEnabled}

                    >
                         {/* Map through the roles and filter out the 'admin' role if the user is not an admin */}
                            {roles !== null &&
                                roles
                                    .filter((role: any) =>
                                        // Only show the 'admin' role if the user is an admin
                                        contextUser.contextUserIsAdmin || role.roleName !== 'admin'
                                    )
                                    .map((rolerecord: any, index: number) => (
                    <Option key={rolerecord.roleName} value={rolerecord.roleName}>
                                            {rolerecord.roleName}
                    </Option>
                                    ))
                            }
                    </Select>
                    </Form.Item>

                }
                 {/* ops-2823 */}
                {
                      (contextUser.contextUserIsAdmin && this.props.usersList.userMaint.pageName === "MyProfile" &&  (roles.length || 0 ) !== 0) &&
                     <Form.Item
                         name="orgHomeRole"
                         label="Role"
                         rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                     >
                         <Select 
                             data-id="users-maint-info-home-org-role"
                             showSearch
                             showArrow={true}
                             allowClear                            
                             placeholder="Select..."
                             optionFilterProp="children"
                             className="select-before" 
                         >
                             {roles !== null && roles.map((rolerecord:any, index:number) => (
                                 <Option key={rolerecord.roleName} value={rolerecord.roleName}>
                                     {rolerecord.roleName}
                                 </Option>
                             ))}
                         </Select>
                     </Form.Item>
                }
               
               {/* ops-2784; ops-2823 */}
                {
                    // !( record.objectAdmin || (contextUser.contextUserIsAdmin && (record.userId || "") === "")) &&

                    ((!contextUser.contextUserIsAdmin && this.props.usersList.userMaint.pageName === "MyProfile") ) &&
                    <>
                        <Form.Item 
                            name="orgHomeRole"
                            style={{ display: 'none' }}
                        >
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.Item
                            label="Role"
                        >
                            <span> {record.orgHomeRole}</span>                           
                        </Form.Item>
                    </>
                }
                {/* ops-2784; ops-2823 */}
                {                    
                   ((roles.length || 0) === 0) &&
                   <>
                   <Form.Item 
                       name="orgHomeRole"
                       style={{ display: 'none' }}
                   >
                       <Input type="hidden" />
                   </Form.Item>

                   <Form.Item
                       label="Role"
                   >
                       <span>{appContext.roleName}</span>
                   </Form.Item>
               </>
                }

            </Card>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        usersList: state.usersReducers.usersState.usersList,
        roleList: state.usersReducers.usersState.roleList,
        permissions: state.contextReducer.data
    };
};

export default connect(
    mapStateToProps,
    actions
)(UserHomeOrg);