import { Button, Col, Input, Popconfirm, Popover, Row, Space, Table, Tooltip, message } from "antd";
import React, { Component } from "react";

import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { closePopover, getPermissions } from "../../../utils/commonHelpers";
import { appConfigs } from "../../../utils/configurations";
import * as actions from "./actions";
import QuestionBankMaint from "./QuestionBankMaint";
import "./questions.css";
//import QuestionBankMaint from "./questionBankMaint";

const { Search } = Input;

interface Prop{
    qbSetSearch: Function,
    qbSetPagination:Function,
    getQbList: Function,
    qbMaintOpen: Function,
    context: any,
    questionBankList: any,
    qbMaint:Function,
    qbDelete: Function,
    // getQbListForExport: Function,
    getAssetsToAssignQuestionbank: Function,
    permissions: any
    
}

interface State {
    csvData: any,
    csvFileName: string
    isOperator?: boolean
    sortColumn: string | undefined
    sortDirection: string | undefined,
    page: Number
}

class QuestionBank extends Component<Prop, State> {
    state: State = {
        csvData: [],
        csvFileName: "",
        isOperator:true,
        sortColumn: "questionbankname",
        sortDirection: "ascend",
        page:1
    };

    drawerWidth: number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);

    // getValuesFromPath=(index:number)=>{
    //     const pathArr = history.location.pathname.split("/");
    //     return pathArr[index];
    // }
    // getStateFromHistory=()=>{
    //   return  ((history.location.state || true) as State).isOperator;
    // }

    componentDidMount(){
    // if (!this.props.context.user.contextUserIsAdmin) {
       this.props.qbSetPagination(this.onPaginationChange);
        this.getQbList();
        let orgId = 'a09ad270-3b21-11e7-893e-f94b4bc7246b';//only taylor Org
        this.props.getAssetsToAssignQuestionbank(this, null, null, 'all', orgId);
        
    }

    getQbList() {
            this.props.getQbList(this, this.getQbSuccess, this.getQbFail ,this.state.sortDirection, this.state.sortColumn?.toLowerCase(), this.state.page, null,null);
    }

    getQbSuccess = (data: any) => {
       //this.qbMaintOpenDo((data[0]||[]));

    }

    getQbFail = (data:any) => {

    }

    qbMaintOpenDo = (record: any) => {
        const title = Object.keys((record)).length === 0 ? "New QuestionBank" : (record.qbId || "") === "" ? "New QuestionBank" : "Edit QuestionBank ::" + record.operatorIdValue;
      //  this.props.qbMaintOpen(this, title, this.drawerWidth, record);
    }

   
   onPaginationChange = (page: number) => {
    this.setState({page: page});
     this.props.getQbList(this, this.getQbSuccess, this.getQbFail, this.state.sortDirection, this.state.sortColumn?.toLowerCase(), page, null, null);
   };

   onAction = (e:any, record:any, action:string) => {
    e.preventDefault();
    if ( action === "add-qb" ) {
       this.props.qbMaintOpen(this, "New Question Bank", this.drawerWidth,{});
    }
    else if ( action === "edit-qb" ) {
       this.props.qbMaintOpen(this, "Edit Question Bank :: " + record.questionBankName, this.drawerWidth, record);
    }
    else if (action === "delete") {
       this.props.qbDelete(this, this.qbDeleteSucess, null, record.qbId);
    }  

    closePopover();  
           
  }

  qbDeleteSucess = (data:any) => {
    message.success("Question Bank has been successfully deleted");
    this.onPaginationChange(this.props.questionBankList.tableConfig.pagination.current);
 }

    actions = (record: any, appContext: any, contextUser: any,isUpdate: any, isDelete: any) => (
    <Popover
        content={
            <div className="center-div">
                  <Space size="small">
                      {
                         // (isUpdate) &&
                          <Tooltip title="Edit Question Bank">
                              <Button data-id="qb-edit-button" type="default" icon={<EditOutlined />} onClick={(e) => { this.onAction(e, record, "edit-qb") }} />
                          </Tooltip>
                      }
                    {/* {!record.activeInScope && this.confirmStatus(record, "activate")}
                        {record.activeInScope && this.confirmStatus(record, "deactivate")}
                        */}
                        {//(isDelete) && 
                        this.confirmDeletion(record)} 
                </Space>
            </div>
        }
        title={
            <div className="center-div">Actions</div>
        }
        trigger="click"
    >
        <Button data-id="qb-actions-button" type="default" icon={<EllipsisOutlined />} />  
    </Popover>
);

// confirmStatus = (record:any, action:string) => (
//     <Popconfirm
//         title={"Are you sure to " + (action === "activate" ? "activate" : "deactivate") + " this user?"}
//        // onCancel={closePopover}
//         onConfirm={(e) => {this.onAction(e, record, action)}}
//         okText="Yes"
//         cancelText="No"
//     >
//         <div>
//             {
//                 action === "activate" &&
//                 <Tooltip title="Activate User"> 
//                     <Button data-id="qb-activate-button" type="default" icon={<UserAddOutlined />} />
//                 </Tooltip>
//             }
//             {
//                 action !== "activate" &&
//                 <Tooltip title="Deactivate User"> 
//                     <Button data-id="qb-deactivate-button" type="default" icon={<UserDeleteOutlined />} />
//                 </Tooltip>
//             }
//         </div>
//     </Popconfirm>
// );
confirmDeletion = (record:any) => (
    <Popconfirm
        title={"Are you sure to delete this question bank?"}
        // onCancel={closePopover}
        onConfirm={(e) => {this.onAction(e, record, "delete")}}
        okText="Yes"
        cancelText="No"
    >
        <Tooltip title="Delete Question Bank">
            <Button data-id="qb-delete-button" type="default" icon={<DeleteOutlined />} />
        </Tooltip>
    </Popconfirm>
);
  onSearch = (searchText: string) => {
    let me = this;
   this.props.qbSetSearch((searchText || ""));
    //  if((searchText || "")==="")
    //  {
    //    history.push("/operators");
    //  }
    // setTimeout(function() {
    //     me.props.getQbList(me, null, null, 1, searchText);   
    // }, 100);
    this.props.getQbList(this, this.getQbSuccess, this.getQbFail, this.state.sortDirection, this.state.sortColumn?.toLowerCase(), this.state.page, null,searchText);
  }

   onSearchChange = (e: any) => {
       let value = e.target.value;
       this.props.qbSetSearch((value || ""));
    }


    // handleChange = (info: any) => {
    //     const result = getPermissions(this.props.permissions.permissions.operator_support);   
    //     if (info.file.status === "done") {
    //         message.success("File has been successfully processed");
    //      //   this.onPaginationChange(this.props.questionBankList.tableConfig.pagination.current);
    //     }
    //     else if (info.file.status === "error") {
    //         let formErrors: any = [];

    //         if (info.file.response.errors && (info.file.response.errors.length || 0) !== 0) {
    //             info.file.response.errors.forEach(function (record: any) {
    //                 if (record.type === "form") {
    //                     formErrors.push(record.message);
    //                 }
    //             });
    //         }
    //         if (formErrors.length > 0) {
    //             formErrors.forEach((error: string) => {
    //                 notification.error({
    //                     message: "File Processing",
    //                     description: error
    //                 });
    //             });
    //         }
    //         else {
    //             message.error(`${info.file.name} file upload failed.`);
    //         }
    //     }
    // }; 

   render(){
    const { contextUser, appContext } = this.props.context;
    const { tableConfig, tableData } = this.props.questionBankList;
   
    const result = getPermissions(this.props.permissions.permissions.operator_support);    
    // if (!this.props.context.user.contextUserIsAdmin && !result[1]){
    //     history.push("/not-found")    
    // }
    let isCreate = result[0];
    let isRead = result[1];
    let isUpdate = result[2];
    let isDelete = result[3];

    const tableColumns = [
        {
            title: "Question Bank Name",
            dataIndex: "questionBankName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: this.state.sortColumn == 'questionbankname' ? this.state.sortDirection : false,
        },
        {
            title: "Assets Assigned",
            dataIndex: "assetsAssigned",
        },
        {
            title: "Date Created",
            dataIndex: "createdAt",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: this.state.sortColumn == 'createdat' ? this.state.sortDirection : false,
            render: (text: any, record: any) => (
                <>
                {
                    <span>{new Date(record.createdAt).toLocaleString()}</span>
                }
                </>
            )
        },
        {
            title: "Actions",
            key: "action",
            align: "center" as "center",
            render: (text: any, record: any) => (
              this.actions(record, appContext, contextUser, isUpdate, isDelete)
              // this.actions(record, false, false,null,null)
             )
           
        }
       ]
   
       const sortQuestionBanksTable = (pagination: any, filters: any, sorter: any): void => {
        let sortColumn: string | undefined = undefined;
        let sortDirection: string | undefined = undefined;
        if (sorter && Object.keys(sorter).length > 0) {
            const { column, order } = sorter;
            sortDirection = order;
            sortColumn = column?.dataIndex.toLowerCase();//.replace(/\s/g, ''
        }
        // setPage(pagination.current);
        // setSortColumn(sortColumn || '');
        // setSortDirection(sortDirection || '');
        this.setState({sortColumn: sortColumn?.toLowerCase(), sortDirection: sortDirection, page: pagination.current});
        this.props.getQbList(this, this.getQbSuccess, this.getQbFail, sortDirection, sortColumn?.toLowerCase(), pagination.current, null,null);
       }

    return (
        <div data-id="questionbank-container" className="layout-content">
         <Row gutter={8}>
                    <Col xs={24} lg={6}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                    {/* <BackNavigation />   */}
                                </Tooltip>
                                Question Bank Management
                            </div>
                        </div>
                    </Col>
                   
                  
                    <Col xs={24}  lg={18} className="content-header-controls">
                  
                    <Space size="middle">
                        {
                            //(isRead) &&
                            <Search
                                data-id="questionbank-search-field"
                                key="search-field"
                                placeholder="Search by ID, Access Card, Asset, Tags"
                                enterButton="Search"
                                size="middle"
                                allowClear
                                onSearch={this.onSearch}
                                onChange={this.onSearchChange}
                                className="search-field"
                                value= {this.props.questionBankList.searchText}

                            />
                        }
                        {
                            //(isCreate) &&
                            <Tooltip title="Create New QuestionBank">
                                <Button data-id="qb-search-new-qb-button" type="primary"
                                    icon={<PlusOutlined />} onClick={(e) => this.onAction(e, {}, "add-qb")}>
                                    <span>QuestionBank</span>
                                </Button>
                            </Tooltip>
                        }
                        </Space>
                    </Col>
                </Row>
            <div className="mt-15">
                {//(isRead) &&
                    <Table
                        data-id="qbList-data"
                        {...tableConfig}
                        rowKey={(record) => record.qbId}
                        columns={tableColumns}
                        dataSource= {tableData} //{dataSource}
                        onChange={sortQuestionBanksTable}

                    />
                }
                <QuestionBankMaint sortColumn={this.state.sortColumn} sortDirection={this.state.sortDirection} page={this.state.page}/>  
                </div>
        </div>
    );
   }
}

const mapStateToProps = (state:any) => {
    return {
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
        questionBankList: state.questionsReducer.questionState.questionBankList
    };
};

export default connect(
    mapStateToProps,
    actions
)(QuestionBank);
