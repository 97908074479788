import React, { useEffect, useState } from "react";
import { Modal, Table, Checkbox, Space, Tooltip, Input, Select, Col, Row, Button, message, Divider, Popover, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";

import { connect } from "react-redux";
import * as actions from "../actions";
import * as contractActions from "../../Admin/Contracts/actions";
import moment from "moment";

import { appConfigs } from "../../../utils/configurations";

import "../assets.css";

const { Option } = Select;

interface Prop {
    getAssets: Function,
    assetMaintClose: Function, 
    getCustomerBucketDetails: Function,   
    getServicePlansList: Function,   
    assignServicePlanToGateway: Function,   
    getGatewayContractsDetails: Function,   
    context: any,
    assetsList: any,
    servicePlansList: any,
    customerBucketDetails: any,
    getServicePlansComparisionSheet:any,
}


const AssetContractsDetails: React.FC<Prop> = (props) => {

    const formRef = React.createRef<FormInstance>();
    const [showZeros, setShowZeros] = useState(false)
    const [contractsHistoryLoading, setContractsHistoryLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const [GatewayContractsDetails, setGatewayContractsDetails] = useState<any>({billingContractHistory: [], renewalContractHistory: []})
    
    useEffect(() => {
        props.getServicePlansList()
        props.getServicePlansComparisionSheet();
        props.getCustomerBucketDetails(props.context.appContext.orgId)
        props.getGatewayContractsDetails(props.assetsList.assetMaint.record.gatewayId, onGetContractDetailsSuccess, onGetContractDetailsFailure)
        switchContractsHistoryLoading(true)
    }, [props.assetsList.assetMaint.record.orgId]);

console.log(props.assetsList.assetMaint.tutorials.tutorialsList);
    useEffect(() => {
        if(!props.servicePlansList.length) {
            props.getCustomerBucketDetails(props.context.appContext.orgId)
        }
    }, [props.servicePlansList]);


    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleOk = () => {

        setIsModalVisible(false);

        formRef.current?.setFieldsValue({
            userConfirmed: true,
        })

        props.assignServicePlanToGateway(formRef.current?.getFieldsValue(), servicePlanAssignedSuccess, servicePlanAssignedError)

    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const toggleZeros = () => {
        setShowZeros(!showZeros)
    }

    const switchContractsHistoryLoading = (status: boolean) => {
        setContractsHistoryLoading(status)
    }

    const onGetContractDetailsSuccess = (contractsDetails: any) => {
        if(contractsDetails){
            setGatewayContractsDetails({
                billingContractHistory: contractsDetails.billingContractHistory ? JSON.parse(contractsDetails.billingContractHistory) : [], 
                renewalContractHistory: contractsDetails.renewalContractHistory ? JSON.parse(contractsDetails.renewalContractHistory) : []
            })
        }

        switchContractsHistoryLoading(false)
    }
    const onGetContractDetailsFailure = (errorMessage: any) => {
        console.log('errorMessage')
        console.log(errorMessage)
    }

    const onFinishSubmitAssignment = (values: any) => {
        resetFormErrors(null, formRef.current); 
        let partNumber = '';
        let PONumber = '';
        let SONumber = '';

        //Check if duplicate clicks (similar to Clive's issue)

        // console.log('onFinishSubmitAssignment');
        // console.log(formRef.current)
        // console.log(formRef.current?.getFieldsValue(['partNumberId']))
        // console.log(formRef.current?.getFieldValue('partNumberId'))
        if (props.customerBucketDetails) {
            Object.keys(props.customerBucketDetails)?.map((itemKey:any) => {
                if(props.customerBucketDetails[itemKey]?.servicePlanId === formRef.current?.getFieldValue('partNumberId')){
                    partNumber = props.customerBucketDetails[itemKey]?.partNumber
                    PONumber = props.customerBucketDetails[itemKey]?.partNumberBucketEntries[0]?.poNumber
                    SONumber = props.customerBucketDetails[itemKey]?.partNumberBucketEntries[0]?.soNumber
                }
            })
        }
        

        formRef.current?.setFieldsValue({
            orgId: props.context.appContext.orgId,
            gatewayId: props.assetsList.assetMaint.record.gatewayId,
            masterTag: props.assetsList.assetMaint.record.mastertag,
            customerNumber: GatewayContractsDetails?.billingContractHistory[0]?.pts_customer_number,
            billToName: GatewayContractsDetails?.billingContractHistory[0]?.customer_name,
            customerPO: PONumber,
            orderNumber: SONumber,
            partNumber: partNumber,
            userConfirmed: false,
            addLog: true
        })

        if( formRef.current?.getFieldValue('customerNumber') === '' ||
            formRef.current?.getFieldValue('billToName') === '' ||
            formRef.current?.getFieldValue('customerPO') === '' ||
            formRef.current?.getFieldValue('orderNumber') === '' ||
            formRef.current?.getFieldValue('partNumber') === ''
        ){
            message.error("Unable to assign service plan please contact system administrator", 7)
        } else {
            props.assignServicePlanToGateway(formRef.current?.getFieldsValue(), servicePlanAssignedSuccess, servicePlanAssignedError)
        }
    }

    const servicePlanAssignedSuccess = (data: any, resultMessage: any) => {
        
        
        if(resultMessage === 'Renewal Contract Assigned, Activated and Downgraded Successfully'){
            message.success("Service plan assigned, please assign a Lite template!", 7);
            props.getAssets(
                null, null, null, 
                props.assetsList.tableConfig.pagination.current, 
                props.assetsList.status, props.assetsList.searchText, 
                props.assetsList.alertInterval, props.assetsList.gatewayId,
                true
            );
            props.assetMaintClose(null);
        } else {
            message.success(resultMessage, 7);
            setContractsHistoryLoading(true)
            props.getCustomerBucketDetails(props.context.appContext.orgId)
            props.getGatewayContractsDetails(props.assetsList.assetMaint.record.gatewayId, onGetContractDetailsSuccess, onGetContractDetailsFailure)
        }
        
        
    }

    const servicePlanAssignedError = (defaultMessage: any, ServerMessage: any) => {
        if(ServerMessage?.includes('There is an active contract expiring on') || ServerMessage?.includes('There is a pending service plan')){
            setModalMessage(ServerMessage)
            showModal()
        } else {
            message.error(ServerMessage ? ServerMessage : defaultMessage, 7);
        }
    }


    const customerBucketTableColumn = [
        {
            title: 'Service Plan Description',
            dataIndex: 'packageName',
            render: (text: string) => {
                text = text.replace(/\s*\(.*?\)$/, '');
                //text = text.replace(/(Local Plan)+/gi, 'Local Plan (WiFi Only)');
                if (/Local Plan/gi.test(text)) 
               {        
                    text = 'Local Plan (WiFi Only)'; 
                }
                return text;
            },
        },
        {
            title: 'Service Plan Part Number',
            dataIndex: 'partNumber',
        },
        {
            title: 'Available Quantity',
            dataIndex: 'total_available_quantity',
            render: (text: string, record: any) => {
                const content = (
                    <table className="styled-table" style={{width: '550px'}}>
                        <thead>
                            <tr>
                                <th style={{whiteSpace: 'nowrap' }}>Created At</th>
                                <th style={{whiteSpace: 'nowrap' }}>PO Number</th>
                                <th style={{whiteSpace: 'nowrap' }}>SO Number</th>
                                <th>Purchased Quantity</th>
                                <th>Used (Assigned) Quantity</th>
                                <th>Available Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            { record.partNumberBucketEntries?.map((bucketEntry:any) => 
                                <tr key={bucketEntry.bucketId}>
                                    <td style={{whiteSpace: 'nowrap' }}>{ moment(bucketEntry.createdAt).format('MM/DD/YYYY HH:mm:ss') }</td>
                                    <td style={{whiteSpace: 'nowrap' }}>{bucketEntry.poNumber}</td>
                                    <td style={{whiteSpace: 'nowrap' }}>{bucketEntry.soNumber}</td>
                                    <td>{bucketEntry.purchasedQuantity}</td>
                                    <td>{bucketEntry.purchasedQuantity - bucketEntry.availableQuantity}</td>
                                    <td>{bucketEntry.availableQuantity}</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                );

                if(record.total_available_quantity > 0) {
                    return (
                        <Popover placement="topLeft" key={record.partNumber} content={content} title="PO History">
                            <span style={{ cursor: "pointer", color: "#119FFB", textDecoration: "underline" }}>{record.total_available_quantity}</span>
                        </Popover >
                    )
                } else {
                    return (
                        <span style={{ cursor: "pointer" }}>{record.total_available_quantity}</span>
                    )
                }

            }
        },
    ];

    const orgBucketTableColumn = [
        {
            title: 'Service Plan Description',
            dataIndex: 'packageName',
            render: (text: string) => {
                text = text.replace(/\s*\(.*?\)$/, '');
                //text = text.replace(/(Local Plan)+/gi, 'Local Plan (WiFi Only)');
                if (/Local Plan/gi.test(text)) 
                {        
                     text = 'Local Plan (WiFi Only)'; 
                }

                return text;
            },
        },
        {
            title: 'Service Plan Part Number',
            dataIndex: 'partNumber',
        },
        {
            title: 'Available Quantity',
            dataIndex: 'total_available_quantity',
            render: (text: string, record: any) => {
                const content = (
                    <table className="styled-table" style={{width: '550px'}}>
                        <thead>
                            <tr>
                                <th style={{whiteSpace: 'nowrap' }}>Transfer Date</th>
                                <th>Transferred From (Org)</th>
                                <th>Transferred In Quantity</th>
                                <th>Returned Quantity</th>
                                <th>Used (Assigned) Quantity</th>
                                <th>Available Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            { record.partNumberBucketEntries?.map((bucketEntry:any) => 
                                <tr key={bucketEntry.bucketId}>
                                    <td style={{whiteSpace: 'nowrap' }}>{ moment(bucketEntry.createdAt).format('MM/DD/YYYY HH:mm:ss A') }</td>
                                    <td style={{textAlign: 'center', whiteSpace: 'nowrap'}}>{bucketEntry.transferredInFromOrgName}</td>
                                    <td style={{backgroundColor: 'rgba(3, 190, 252, 0.1)', textAlign: 'center'}}>{bucketEntry.transferredInQuantity}</td>
                                    <td style={{backgroundColor: 'rgba(150, 32, 107, 0.1)', textAlign: 'center'}}>{bucketEntry.transferredOutQuantity ? bucketEntry.transferredOutQuantity : "0"}</td>
                                    <td style={{textAlign: 'center'}}>{bucketEntry.transferredInQuantity - bucketEntry.availableQuantity - bucketEntry.transferredOutQuantity}</td>
                                    <td style={{backgroundColor: 'rgba(11, 156, 110, 0.1)', textAlign: 'center' }}>{bucketEntry.availableQuantity }</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                );

                if(record.total_available_quantity > 0) {
                    return (
                        <Popover placement="topLeft" key={record.partNumber} content={content} title="Transfer History">
                            <span style={{ cursor: "pointer", color: "#119FFB", textDecoration: "underline" }}>{record.total_available_quantity}</span>
                        </Popover >
                    )
                } else {
                    return (
                        <span style={{ cursor: "pointer" }}>{record.total_available_quantity}</span>
                    )
                }

            }
        },
    ];
          
    return (     

        <>

            <Modal title="Entry Warning" visible={isModalVisible} okText="Yes, Add" cancelText="No" okType="danger" onOk={handleOk} onCancel={handleCancel}>
                <span>{ modalMessage }</span><br />
                <span>Are you sure you still want to add this service plan?</span> 
            </Modal>

            <Form
                id="assignSerivePlanToGateway"
                ref={formRef}
                layout="vertical"
                onFinish={onFinishSubmitAssignment}
            >   
                <Row gutter={8}>

                    <Form.Item name="orgId" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="gatewayId" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="masterTag" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="addLog" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="customerNumber" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="billToName" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="customerPO" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="orderNumber" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="partNumber" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="userConfirmed" >
                        <Input value="" type='hidden' />
                    </Form.Item>

                    <Col style={{ marginTop: '10px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                            label="Select Service Plan To Assign"
                            name="partNumberId"                                 
                            rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                        >
                        
                            <Select
                                placeholder="Select Service Plan"
                                showSearch
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return  option?.children[0]?.props?.children[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {
                                    props.customerBucketDetails != null && 
                                        Object.keys(props.customerBucketDetails)?.map((itemKey:any) => (
                                            (props.customerBucketDetails[itemKey]?.total_available_quantity > 0) &&
                                                <Option key={props.customerBucketDetails[itemKey]?.servicePlanId} value={props.customerBucketDetails[itemKey]?.servicePlanId}>
                                                    <span>{props.customerBucketDetails[itemKey]?.packageName} </span>
                                                    {/* <span style={{ float: 'right' }}>Quantity: {props.customerBucketDetails[itemKey]?.total_available_quantity}</span>  */}
                                                </Option>
                                            
                                        ))
                                }
                            </Select>
                            
                        </Form.Item>
                    </Col>

                    <Divider></Divider>

                    <Col span={24}>
                        <h3>Asset Detailed View</h3>
                        <table className="styled-table" style={{width: '100%'}}>
                            <thead>
                                <tr>
                                    <th>Mastertag</th>
                                    <th>Created On</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Part Number</th>
                                </tr>
                            </thead>
                            
                            {
                                contractsHistoryLoading &&
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign: "center"}} colSpan={4}>Loading...</td>
                                        </tr>
                                    </tbody>
                            }
                            
                            {
                                !contractsHistoryLoading &&
                                    <tbody>
                                        {GatewayContractsDetails.billingContractHistory?.map((billing: any) =>
                                            <tr key={billing.id}>
                                                <td>{billing.mastertag}</td>
                                                <td>{billing.built_on}</td>
                                                <td>{billing.contract_start_date}</td>
                                                <td>{billing.contract_end_date}</td>
                                                <td>{billing.partNumber}</td>
                                            </tr>
                                        )}
                                        {GatewayContractsDetails.renewalContractHistory?.map((renewal: any, index: number) =>
                                            <tr key={renewal.id}>
                                                <td>{renewal.mastertag}</td>
                                                <td>{renewal.created_date}</td>
                                                <td>{renewal.contract_start_date}</td>
                                                <td>{renewal.contract_end_date}</td>
                                                <td>{renewal.partNumber}</td>
                                            </tr>
                                        )}
                                    </tbody>
                            }

                        </table>
                    </Col>

                    <Divider></Divider>

                    {
                        props.customerBucketDetails?.length > 0 &&
                        <Col style={{ marginTop: '10px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h3>
                                <span>Available Service Details</span>
                                {/* <span onClick={toggleZeros} style={{ marginLeft: "3px", fontSize: '12px', color: "#1890FF", textDecoration: "underline", cursor: "pointer", userSelect: "none" }}>{!showZeros ? 'Show' : 'Hide'} Zero Quantities</span> */}
                                {/* <Button 
                                    onClick={toggleZeros} 
                                    size='small'
                                    type={!showZeros  ? "primary" : "default"}
                                    style={{ float: 'right'}}
                                >{!showZeros ? 'Show' : 'Hide'} Zeros</Button> */}
                                {/* Commenting this below checkbox for OPS-6347 */}
                                {/* <Checkbox 
                                    onClick={toggleZeros}
                                    defaultChecked={showZeros}
                                    style={{ float: 'right' }}
                                >
                                    <span style={{paddingLeft: "2px"}}>Show Zero Quantities</span>
                                </Checkbox>  */}
                            </h3>
                            <Table
                                pagination={false}
                                columns={props.context.user.contextOrgHasTransferredContracts ? orgBucketTableColumn : customerBucketTableColumn}
                                dataSource={!showZeros ? props.customerBucketDetails?.filter((details: any) => details['total_available_quantity'] > 0) : props.customerBucketDetails}
                                rowKey={(record) => record.partNumber}
                                size="small"
                            />
                            <hr/>
                           
                            {props.assetsList.assetMaint.tutorials.tutorialsList.map((tutorial: any) => 
                                    (tutorial.category === "Userguide" &&tutorial.id === 20 &&
                                        <div><a href={tutorial.link} target="_blank">Check Here</a><span> to know about our Service Plans</span> </div>
                            ))}
                        </Col>
                                    
                    }
                    

                </Row>

            </Form>

        </>
        
    )
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        assetsList: state.assetsReducer.assetsState.assetsList,
        servicePlansList: state.contractsReducers.contractsState.contracts.servicePlansList,
        customerBucketDetails: state.contractsReducers.contractsState.contracts.customerBucketDetails
    };
};

export default connect(
    mapStateToProps,
    {
        ...actions,
        ...contractActions
    }
)(AssetContractsDetails);