import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
 
import LoginFooter from "./LoginFooter";
import LoginError from "./LoginError";
import ForgotPassword from "./ForgotPassword";
import { setLogin, setElementDisplay, loadingIndicatorStart, clientStorageClear, checkOktaTokenExpired } from "../../utils/commonHelpers";
 
import { connect } from "react-redux";
import * as actions from "./actions";
 
import config from './config';
import { appConfigs } from "../../utils/configurations";
import MaintenancePage from './MaintenancePage';
 
interface Prop {
    loginGet: Function,
    login: any,
    context: any
}
 
const Login: React.FC<Prop> = (props) => {
   
    let helpLinksTimer:any;
 
    const { authService } = useOktaAuth();
   
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
    const [currentUrl] = useState<String>((window.location.hash ? "/" + window.location.hash : window.location.pathname).toLowerCase());
    //
    //Identify intermediate Okta page load, usually URL starts with /#code=...        
    //
    const [intermediateOktaFlow] = useState<Boolean>(window.location.hash.startsWith("#code="));
    const [parkerOktaFlow] = useState<Boolean>(window.location.search.startsWith("?fromLogin=true"));
    const [isAccessDenied] = useState<Boolean>(window.location.href.includes("&error=access_denied"));
 
    const openErrorModal = () => {
        setErrorModalVisible(true);
    }
 
    const closeErrorModal = () => {
        if (errorModalVisible) {
            setErrorModalVisible(false);
        }
    }
   
    const forgotPasswordOpen = (e:any) => {
        e.preventDefault();
 
        setElementDisplay("#sign-in-widget", "none");
        setForgotPasswordVisible(true);
    }
   
    const forgotPasswordClose = () => {
        if (forgotPasswordVisible) {
            setElementDisplay("#sign-in-widget", "block");
            setForgotPasswordVisible(false);
           
        }
    }
 
    const renderEl = (oktaSignIn: any) => {
       
        oktaSignIn.renderEl(
            {
                el: '#sign-in-widget'
            },
            function success(res: any) {                                    
            },
            function error(err: any) {
                console.error(err);
            }
        );
    }
 
    const goToPage = (redirectUri: string, url:string) => {
        loadingIndicatorStart();
        setTimeout(function () {
            window.location.href = redirectUri + url;
        }, 150);    
    }
 
    const reloadPage = () => {
        loadingIndicatorStart();
        setTimeout(function () {
            window.location.href = window.location.href + "";
        }, 150);    
    }
 
    const tokenFromUrl = (oktaSignIn: any, redirectUri:string) => {
        oktaSignIn.authClient.token.parseFromUrl()
            .then(
                function success(res: any) {                    
                    authService.updateAuthState();
 
                    const accessToken = res.tokens.accessToken;
                    const idToken = res.tokens.idToken;
 
                    oktaSignIn.authClient.tokenManager.add("accessToken", accessToken);
                    oktaSignIn.authClient.tokenManager.add("idToken", idToken);
                   
                    //ops-2656,2622 fix
                    localStorage.removeItem("isPageRefreshed");
                    let url=localStorage.getItem("pathname") || "/map";
                    if(url==="/" || url==="/not-found")
                    {
                        url="/map";
                    }
                    goToPage(redirectUri, url);
                },
                function error(err: any) {                      
                    const loginError = {
                        "stop" : true,
                        "errorCode": null,
                        "errorObject": [
                            {
                                "code": err.hasOwnProperty("errorCode") ? err.errorCode : null,
                                "message": err.hasOwnProperty("message") ? err.message : "An error has been detected - Please contact Parker Support"
                            }            
                        ]
                    };
           
                    window.sessionStorage.setItem("loginError", JSON.stringify(loginError));  
                    goToPage(window.localStorage.getItem("loginUrl") || window.location.href, "")
                }
            )
    }
 
    const sessionCheck = (oktaSignIn: any) => {
        oktaSignIn.authClient.session.exists()
            .then(
                function (exists: boolean) {
                    if (exists) {
                        if(isAccessDenied)
                        {
                           tokenFromUrl(oktaSignIn, window.location.origin);
                        }
                        else
                        {
                            let isTokenExpired=checkOktaTokenExpired();
                            if(isTokenExpired && !parkerOktaFlow)
                            {
                                clientStorageClear();
                                renderEl(oktaSignIn);
                            }
                            else
                            {
                            loadingIndicatorStart();
                            oktaSignIn.authClient.token.getWithRedirect({
                            responseType: 'code'
                            })
                            }
                        }    
                    }
                    else {
                        renderEl(oktaSignIn);
                    }
                },
                function error(err: any) {
                    renderEl(oktaSignIn);
                }
            )
            .catch(
                function (err: any) {
                    renderEl(oktaSignIn);
                }
            );
    }
   
    const hasLoginError = ():boolean => {
        return (window.sessionStorage.getItem("loginError") || "{}") !== "{}";
    }
 
    const processLoginError = (oktaSignIn: any) => {
        renderEl(oktaSignIn);
        if (JSON.parse(window.sessionStorage.getItem("loginError") || "{}").hasOwnProperty("silent")) {
            window.sessionStorage.removeItem("loginError");
        }
        else {
            openErrorModal();
        }
    }
 
    const customizeHelpLinks = () => {
        if (document.querySelectorAll("div.auth-footer a.list.forgot-password").length === 0) {
            let container:any = document.querySelectorAll("div.auth-footer");
            if (container.length > 0) {
                let newAnchor = document.createElement("a");  
                newAnchor.appendChild(document.createTextNode(appConfigs.app.login.en["forgotpassword"]));  
                newAnchor.addEventListener("click", forgotPasswordOpen);
                newAnchor.classList.add("list");
                newAnchor.classList.add("forgot-password");
                container[0].appendChild(newAnchor);  
                if (document.querySelectorAll("input#okta-signin-password").length > 0) {
                    clearInterval(helpLinksTimer);
                }
            }
        };  
    }    
 
    useEffect(() => {
 
        const { pkce, issuer, clientId, scopes } = config.oidc;
 
        if (!intermediateOktaFlow && !parkerOktaFlow) {
            completeLogout();
            props.loginGet(null, loginGetSuccess, null, currentUrl);      
            //eslint-disable-next-line
            helpLinksTimer = setInterval(customizeHelpLinks, 1000);
        }
 
        const oktaSignIn = new OktaSignIn({            
            baseUrl: issuer.split('/oauth2')[0],
            clientId,
            redirectUri: window.location.origin,
            features: {
                idpDiscovery: true
            },
            idpDiscovery: {
                requestContext: window.location.origin
            },
            logo: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
            i18n: {
                en: appConfigs.app.login.en
            },
            authParams: {
                pkce,
                issuer,
                display: 'page',
                responseMode: 'fragment',
                scopes,
            },
        });
       
        if (oktaSignIn.hasTokensInUrl()) {
            tokenFromUrl(oktaSignIn, window.location.origin);
        } else {
            if (hasLoginError()) {
                processLoginError(oktaSignIn);
            }
            else {
                sessionCheck(oktaSignIn);
            }        
        }
 
    }, []);
   
    const loginGetSuccess = (data:any, orgUrl:string) => {
        setTimeout(function () {
            setLogin(data.headerColor, data.logoUrl, data.loginLogoUrl, data.loginBackgroundUrl, data.termsAndConditions);
        }, 150);    
 
        if ((orgUrl || "") !== "" ) {
            const loginUrl = ((orgUrl || "login") !== "login" ? "/login/" : "") + orgUrl.toLocaleLowerCase();
            window.localStorage.setItem("loginUrl", loginUrl);
            if (currentUrl !== loginUrl) {
                loadingIndicatorStart();
                window.location.href = loginUrl;
            }
        }
        else {
            window.localStorage.setItem("loginUrl", "/login");
        }
    }
 
    const completeLogout = () => {
        const logoutInProgress = window.localStorage.getItem("logoutInProgress") || "";
 
        if (logoutInProgress !== "") {
            window.localStorage.removeItem("logoutInProgress");
           
            //ops-2656 fix
            window.localStorage.removeItem("pathname");
            window.localStorage.removeItem("isPageRefreshed");
 
            const pathname = window.location.pathname.toLowerCase();
            const loginUrl = window.localStorage.getItem("loginUrl");
           
            if (pathname !== loginUrl) {
                loadingIndicatorStart();
                window.location.href = loginUrl || "/login";
            }
        }
    }
    const href = window.location.href.toLowerCase();
    let showHelp = true;
    if (href.includes(appConfigs.server.hoistServerURL.toLowerCase()) ||
        href.includes(appConfigs.server.toyotaServerURL.toLowerCase()))
    {
        showHelp = false;
    }
 
    return (
       
        appConfigs.app.enableMaintenanceMode?
        <div ><MaintenancePage/></div>:
        <>
            {
                intermediateOktaFlow &&
                <div style={{ padding: 20 }}>Loading...</div>
            }
            {
                !intermediateOktaFlow && showHelp?
                <div data-id="login-container" className="layout-content">
 
                    <div id="sign-in-widget" />  
 
                    <LoginError visible={errorModalVisible} closeErrorModal={closeErrorModal}/>            
                    <ForgotPassword visible={forgotPasswordVisible} forgotPasswordClose={forgotPasswordClose}/>
                    <LoginFooter />
                    <div className="loginTermsAndConditions" style={{ display: 'none'}}></div>              
                </div>:<div data-id="login-container" className="toyota-layout-content">
 
                    <div id="sign-in-widget" />  
 
                    <LoginError visible={errorModalVisible} closeErrorModal={closeErrorModal}/>            
                    <ForgotPassword visible={forgotPasswordVisible} forgotPasswordClose={forgotPasswordClose}/>
                    <LoginFooter />
                    <div className="loginTermsAndConditions" style={{ display: 'none'}}></div>              
                    </div>
            }
        </>
    );
};
 
const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        login: state.loginReducer.loginState
    };
};
 
export default connect(
    mapStateToProps,
    actions
)(Login);