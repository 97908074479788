import { DeleteOutlined, EditOutlined, EllipsisOutlined, MenuUnfoldOutlined, PlusOutlined, RightOutlined, SubnodeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Input, Popconfirm, Popover, Row, Space, Table, Tag, Tooltip } from "antd";
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import React, { Component } from "react";

import { closePopover, getPermissions, loadingIndicatorStart } from "../../utils/commonHelpers";
import { appConfigs } from "../../utils/configurations";
import OrgMaint from "./OrgMaint";

import { connect } from "react-redux";
import * as rootActions from "../../actions";
import BackNavigation from "../../utils/Components/BackNavigation";
import history from '../../utils/history';
import * as orgSelectorActions from "./actions";

import "./orgs.css";

const { Search } = Input;

interface Prop {
    supportModeSet: Function,
    orgsSetPagination: Function,
    orgsListSetSearch: Function,
    orgsListSetDisplay: Function,
    orgsGet: Function,
    orgGet: Function,
    orgMaintOpen: Function,
    orgDelete: Function,   
    supportMode: any,
    context: any,
    orgsList: any,
    permissions: any,
    permissionsset: any
}

interface State {
    orgTree: any[],   
}

class Orgs extends Component<Prop, State> {

    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);

    state:State = {      
        orgTree: []
    };

    componentDidMount () {
        const orgsList = this.props.orgsList;
        this.props.orgsSetPagination(this.onPaginationChange);
        this.props.orgsGet(this, null, null, "all", this.props.orgsList.scope, 1, orgsList.searchText);
        
    }
    
    onPaginationChange = (page: number) => {
        const orgsList = this.props.orgsList;
        this.props.orgsGet(this, null, null, orgsList.mode, orgsList.scope, page, orgsList.searchText, orgsList.orgId);        
    };

    onAction = (e:any, record:any, action:string) => {
        e.preventDefault();

        if ( action === "add") {
            if (record === null) {
                record={orgIdParent: this.props.orgsList.orgId, orgIdParentName: this.props.orgsList.orgName};
            }
            this.props.orgMaintOpen(this, "New Organization", this.drawerWidth, record);
        }
        else if ( action === "edit") {
            this.props.orgGet(this, this.onOrgEdit, null, record.orgId);            
        }
        else if ( action === "delete") {
            this.props.orgDelete(this, this.orgDeleteSuccess, null, record.orgId);            
        }

        closePopover();       
    }
  
    orgDeleteSuccess = (orgId:string) => {
        this.onPaginationChange(this.props.orgsList.tableConfig.pagination.current);
    }

    viewOrgAssets = (orgName: string) => {
        // history.push("/assets/")
    }

    onSearch = (searchText: string) => { 
        this.setState({ orgTree: [] }); 
        this.props.orgsListSetSearch((searchText || ""));

        const me = this;
        setTimeout(function() {
            me.props.orgsGet(me, null, null, me.props.orgsList.mode, me.props.orgsList.scope, 1, (searchText || ""),  "");
        }, 150);  
    }

    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.orgsListSetSearch((value || ""));
    }

    onOrgEdit = (record:any) => {  
        this.props.orgMaintOpen(this, "Edit Org :: " + record.name, this.drawerWidth, record);
    }

    supportModeSet = (e: any, orgId:string, orgName: string) => {
        this.props.supportModeSet(this, this.supportModeSetSuccess, orgId, orgName);
        closePopover();       
    }

    supportModeSetSuccess = () => {
        loadingIndicatorStart();
        setTimeout(function () {
            window.location.href = "/map"; 
        }, 500); 
       
        //     setTimeout(function () {
        //         reloadPage();
        //     }, 250);
        // }
    }

    confirmDeletion = (record:any, deleteDisable:any) => (
        deleteDisable?
        <Popconfirm
            title={"Are you sure to delete this organization?"}
            onCancel={closePopover}
            onConfirm={(e) => {this.onAction(e, record, "delete")}}
            okText="Yes"
            cancelText="No"
        >
            <Tooltip title="Delete Organization">
                <Button data-id="orgs-delete-org-button" type="default" icon={<DeleteOutlined />}/>
            </Tooltip>
        </Popconfirm>
        :
        <Tooltip title="Organization has either suborgs or assigned assets or templates or users - Cannot Delete">
        <Button data-id="orgs-delete-org-button" disabled={true} type="default" icon={<DeleteOutlined />}/>
        </Tooltip>

    );
    
    support = (record:any) => (
        <Tooltip title="Support">
            <Button data-id="orgs-support-button" type="default" icon={<RightOutlined />} onClick={(e) => {this.supportModeSet(e, record.orgId, record.name)}}/>               
        </Tooltip>
    );

    actions = (record:any, isCreate:any, isRead:any, isUpdate:any, isDelete:any, isSupportMode:any) => (       
        <Popover placement="topRight"
            content={                
                <div className="center-div">
                    <Space size="large">
                        {                            
                            (isUpdate) &&  
                            <Tooltip title="Edit Organization">
                                <Button data-id="orgs-edit-org-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.onAction(e, record, "edit")}}/>               
                            </Tooltip>
                        }
                        {
                            (isCreate) &&   
                            <Tooltip title="Create Sub-Organization">
                                <Button data-id="orgs-add-sub-org-button" type="default" icon={<SubnodeOutlined />} onClick={(e) => {this.onAction(e, {orgIdParent: record.orgId, orgIdParentName: record.name}, "add")}}/>               
                            </Tooltip>
                        }
                        {
                            // isDelete &&   
                            // record.subOrgCount === 0 && 
                            // record.userCount === 0 && 
                            // record.templateCount === 0 && 
                            this.confirmDeletion(record, (isDelete && record.subOrgCount === 0 && 
                                record.userCount === 0 && 
                                record.templateCount === 0 && record.orgAssetsCount === 0))
                        }
                        {
                            (record.subOrgCount > 0 && isRead) &&        
                            <Tooltip title="List Sub-Organizations">
                                 <Button data-id="orgs-list-sub-org-button" type="default" icon={<MenuUnfoldOutlined />} onClick={(e) => {this.drillIn(e, "direct-children", record.orgId, record.name)}}/>
                            </Tooltip>
                        }
                        {
                            (isSupportMode) &&
                            record.orgId !== this.props.supportMode.orgId && 
                            this.support(record)
                        }
                    </Space>
                </div>
            }
            title={
                <div className="center-div">Actions</div>
            }
            trigger="click"
        >          
            <Button data-id="orgs-action-button" type="default" icon={<EllipsisOutlined />} />  
        </Popover>
    );

    drillIn = (e:any, mode:string, orgId:string, orgName:string) => {
        e.preventDefault();

        this.props.orgsListSetDisplay(mode, this.props.orgsList.searchText, mode === "all" ? "" : orgId, mode === "all" ? "" : orgName);

        if (mode === "all") {
            this.setState({ orgTree: [] }); 
        }
        this.props.orgsGet(this, this.drillInSuccess, null, mode, this.props.orgsList.scope, 1, this.props.orgsList.searchText, orgId);
    };
    
    drillInSuccess = (data:any, mode:string) => {
        if (mode !== "all" && data.length > 0) {
            this.setState({ orgTree: data[0].orgTree }); 
        }
    }

    render() {    

        const { tableConfig, tableData } = this.props.orgsList;       
        const result = getPermissions(this.props.permissions.permissions.org_options);        
       
        if (!this.props.context.user.contextUserIsAdmin && !result[1] && !this.props.supportMode.isSupportMode){
            history.push("/not-found")    
        }
        let isCreate = result[0];
        let isRead = result[1];
        let isUpdate =  result[2];
        let isDelete = result[3];

        const supportmode = getPermissions(this.props.permissions.permissions.support_mode);
        let isSupportMode = supportmode[1];

        const tableColumns = [
            {
                title: "Org Name",
                dataIndex: "name",
                render: (name: number, record: any) => (
                    <>
                        {
                            record.subOrgCount > 0 &&                            
                            <Tooltip title="List Sub-Organizations">
                                <a href="# " onClick={(e) => {this.drillIn(e, "direct-children", record.orgId, record.name)}}>
                                    {name}
                                </a>
                            </Tooltip>
                        }
                        {
                            record.subOrgCount === 0 &&
                            <span>{name}</span>
                        }
                    </>
                )
            },            
            {
                title: "Org Address",
                dataIndex: "shortAddress",
                responsive: ["md"] as Breakpoint[]
            },
            {
                title: "Sub-Orgs",
                dataIndex: "subOrgCount",
                key: "subOrgCount",
                align: "center" as "center",
                responsive: ["md"] as Breakpoint[],
                render: (subOrgCount: number, record: any) => (
                    <div className="notifications-tag">
                        {
                            subOrgCount !== 0 && 
                            <Tag color="purple">{subOrgCount}</Tag>
                        }
                    </div>
                )
            },   
            {
                title: "Assets",
                dataIndex: "assetsCount",
                key: "assetsCount",
                align: "center" as "center",
                responsive: ["md"] as Breakpoint[],
                width: 130,
                render: (allAssetsCount: number, record: any) => (
                    <div>
                        <Tooltip overlayStyle={{ minWidth: '200px' }} title={
                            <>
                                <h3 style={{color: 'white'}}>Asset Count Details:</h3><hr />
                                <table style={{width: '100%'}}>
                                    <tr>
                                        <th>This Org's Assets: </th>
                                        <td>
                                            <Tag
                                                color="processing"
                                                style={{width: '100%', textAlign: "center"}}
                                                onClick={(e) => this.viewOrgAssets(record.name)}
                                            >
                                                {record.orgAssetsCount}
                                            </Tag>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Sub-Orgs' Assets: </th>
                                        <td>
                                            <Tag
                                                color="processing"
                                                style={{ width: '100%', textAlign: "center" }}
                                                onClick={(e) => this.viewOrgAssets(record.name)}
                                            >
                                                {record.allAssetsCount - record.orgAssetsCount}
                                            </Tag>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Total Org's Assets: </th>
                                        <td>
                                            <Tag
                                                color="processing"
                                                style={{ width: '100%', textAlign: "center" }}
                                                onClick={(e) => this.viewOrgAssets(record.name)}
                                            >
                                                {record.allAssetsCount}
                                            </Tag>
                                        </td>
                                    </tr>
                                </table>
                                <br />

                                
                            </>
                        }>
                            <Tag
                                color="processing"
                                onClick={(e) => this.viewOrgAssets(record.name)}
                            >
                                {record.allAssetsCount} 
                            </Tag>
                        </Tooltip>

                        {/* <Row align="middle" justify="space-between">
                            <Col span={11}>
                                <Tooltip title="This Org's Assets">
                                    <Tag
                                        color="purple"
                                        style={{width: '100%', textAlign: "center"}}
                                        onClick={(e) => this.viewOrgAssets(record.name)}
                                    >
                                        {record.orgAssetsCount}
                                    </Tag>
                                </Tooltip>
                            </Col>
                            <Col span={11}>
                                <Tooltip title="Sub Org's Assets">
                                    <Tag
                                        color="purple"
                                        style={{ width: '100%', textAlign: "center" }}
                                        onClick={(e) => this.viewOrgAssets(record.name)}
                                    >
                                        {record.allAssetsCount - record.orgAssetsCount}
                                    </Tag>
                                </Tooltip>
                            </Col>
                            <Col style={{ marginTop: "3px" }} span={24}>
                                <Tooltip title="Total Assets">
                                    <Tag
                                        color="processing"
                                        style={{ width: '100%', textAlign: "center" }}
                                        onClick={(e) => this.viewOrgAssets(record.name)}
                                    >
                                        {record.allAssetsCount}
                                    </Tag>
                                </Tooltip>
                            </Col>
                        </Row> */}
                        
                    </div>
                )
            }, 
            {
                title: "Users",
                dataIndex: "userCount",
                key: "userCount",
                align: "center" as "center",
                responsive: ["md"] as Breakpoint[],
                render: (userCount: number, record: any) => (
                    <div className="notifications-tag">
                        {
                            userCount !== 0 && 
                            <Tag color="geekblue">{userCount}</Tag>
                        }
                    </div>
                )
            },
            {
                title: "Templates",
                dataIndex: "templateCount",
                key: "templateCount",
                align: "center" as "center",
                responsive: ["md"] as Breakpoint[],
                render: (templateCount: number, record: any) => (
                    <div className="notifications-tag">
                        {
                            templateCount !== 0 && 
                            <Tag color="geekblue">{templateCount}</Tag>
                        }
                    </div>
                )
            },
            {
                title: "Actions",
                key: "action",
                align: "center" as "center",
                render: (text: any, record: any) => (                    
                   this.actions(record, isCreate, isRead, isUpdate, isDelete, isSupportMode)
                )
            }
        ];

        return (
            <div data-id="orgs-container" className="layout-content">
                <Row gutter={8}>
                    <Col xs={24} lg={10}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                    <BackNavigation />  
                                </Tooltip>                           
                                Organizations
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} lg={14} className="content-header-controls">
                        <Space size="middle">
                            {
                                (isRead) &&
                                <Search
                                    data-id="orgs-search-field"
                                    key="search-field"
                                    placeholder="Search by name, location, phone"
                                    enterButton="Search"
                                    size="middle"
                                    allowClear
                                    onSearch={this.onSearch}
                                    onChange={this.onSearchChange}
                                    className="search-field"
                                    value={this.props.orgsList.searchText}
                                /> 
                            }                       
                            
                            {
                                (isCreate) &&  
                                <Tooltip title="Create New Organization">   
                                    <Button data-id="orgs-new-org-button" disabled={!(this.state.orgTree && this.props.orgsList.orgId)} type="primary" icon={<PlusOutlined />} onClick={(e) => this.onAction(e, null, "add")}>
                                        <span>Organization</span>
                                    </Button>
                                </Tooltip>
                            }                            
                        </Space>
                    </Col>
                </Row>
                
                {
                    this.state.orgTree !== undefined &&
                    (this.state.orgTree.length || 0) !== 0 && 
                    <Breadcrumb data-id="orgs-org-path-container" separator="/" style={{ marginTop: 10}}>
                        <Breadcrumb.Item data-id="orgs-org-path-element" key="breadcrumb-all-orgs" href="# " onClick={(e) => {this.drillIn(e, "all", "", "")}}>All Orgs</Breadcrumb.Item>
                        {
                            this.state.orgTree
                            .filter((record:any, index:number) => (
                                index < this.state.orgTree.length - 1
                            ))
                            .map((record:any, index:number) => (
                                <Breadcrumb.Item key={"breadcrumb-" + index} href="# " onClick={(e) => {this.drillIn(e, "direct-children", record.orgId, record.name)}}>
                                    {record.name}
                                </Breadcrumb.Item>
                            ))
                        }
                    </Breadcrumb>
                }

                <div className="mt-15">                    
                    <OrgMaint iscreate={isCreate} isread={isRead} isupdate={isUpdate}/>  
                    {
                        (isRead) &&
                        <Table  
                            data-id="orgs-data"
                            {...tableConfig}
                            rowKey={(record) => record.orgId}
                            columns={tableColumns}
                            dataSource={tableData}
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        supportMode: state.supportModeReducer,
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
        orgsList: state.orgsReducers.orgsState.orgsList,
        permissionsset: state.permissionsReducer.result
    };
};

export default connect(
    mapStateToProps,
    { 
        ...rootActions,
        ...orgSelectorActions 
    }
  )(Orgs);
