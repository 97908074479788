import { MinusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { appConfigs } from "./../../../../utils/configurations";

interface Prop {
    index: number,
    remove: (index:any)=>void,
    question: string | null
}
const formRef = React.createRef<FormInstance>();
const AddQuestion: React.FC<Prop>=(props) =>{
    const RemoveQuestion=(index:any)=>{

        props.remove(index);

    }

    function getQuestionIndex(index: number) {
        return(index+1);
    }

    return (
    <Row gutter={8}>
        <Col span={22}>
            <Form.Item
                // label= { getQuestionIndex(props.index)}
                name={"question"+ props.index }
                shouldUpdate={true}
                rules={[
                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                    //{ validator:(_, value) => (value.length || 0) > 1 ? Promise.resolve() : Promise.reject(appConfigs.errors.fieldErrors.atLeastTwoCharacters) }
                ]}
            >
                 <Input style={{width:'600px'}} placeholder={"Enter your Question"+getQuestionIndex(props.index)} maxLength={60} value={props.question?props.question:''} disabled={false}></Input>
            </Form.Item>
        </Col>

        <Col span={2}>
                <Button type="primary" name={props.index.toString()}  onClick={(e:any)=>RemoveQuestion(e.currentTarget.name)} icon={<MinusOutlined></MinusOutlined>}></Button>

        </Col>
    </Row>
    // </Form>
    )
  }
  const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        // contractMaintOptions: state.contractsReducers.contractsState.contracts.contractMaintOptions
    };
};
  export default connect(
    mapStateToProps,
    actions
)(AddQuestion);