import React from "react";
import parkerLogo from "../../assets/images/parker-logo.jpg";
import maintenanceLogo from "../../assets/images/settings.png";
const MaintenancePage: React.FC = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        height="351px"
        width="351px"
        src={maintenanceLogo}
        className="image-preview"
        alt="maintenance Logo preview"
      />
      <h1 style={{ fontSize: "20px" ,paddingTop:"20px"}}>
        We are performing some updates to our system to enhance your experience.
        Please
        <br />
        check back in a few hours.
      </h1>
    </div>
  );
};
export default MaintenancePage;