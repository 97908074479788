import React, { useState, useEffect, CSSProperties, useRef } from "react";
import { Card, Space, message, Popconfirm, Tooltip, Button, Popover, Row, Col, Divider, Radio, Dropdown, Select, DatePicker, Collapse } from "antd";
import { EditOutlined, DeleteOutlined, LoadingOutlined, ReloadOutlined, FilterOutlined, DownloadOutlined, FundOutlined, DownOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Template, SignalDatapoint, SignalId, SignalCollectionId, WidgetSignalId, EventData, LineWidgetSignalsConfigs } from "./models";
import { DataStore, WidgetState } from "./state";
import { loadSignalsData } from "./api";
import { createWidget } from "./widgets";
import { constructRows } from "./widgets/common";
import "./WidgetContainer.css";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";
import tzlookup  from "tz-lookup";
import * as Api from "./api";
import { WidgetFilterConfig } from "./WidgetFilterForm";
import usePolling from "../../lib/use-polling";
import { appConfigs } from "../../utils/configurations";
import { isNumber } from "@amcharts/amcharts4/core";
import {
	getAvailableGranularity,
	getTimescaleFilterText,
	loadingIndicatorEnd,
	loadingIndicatorStart,
} from "../../utils/commonHelpers";
import { CSVLink } from "react-csv";
import { State } from "../Dashboard2/state";
import {
	prepareWidgetGPSEvents,
	useExportGPSEvents,
} from "./WidgetExportForm/useExportGPSEvents";
import moment, { Moment } from 'moment'
import 'moment-timezone';
import * as XLSX from 'xlsx';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

interface WidgetHeaderProps {
	onRefresh: () => void;
	onFilter: () => void;
	onExport: () => void;
	isLoading: boolean;
	widgetState: WidgetState;
	state: State;
	data: DataStore;
	rightYAxisData: DataStore;
	userRole: string;
	isCreate: boolean;
	isRead: boolean;
	isUpdate: boolean;
	isDelete: boolean;
	newWidgetHeader: boolean;
	setNewWidgetHeader: Function;
	setRightAxisData: Function;
	setData: Function;
	refreshData: boolean;
	setLoading: Function;
	zoomedTime: [Moment, Moment];
	filterSetting: Function;
	setInitiallyLoaded: Function;
	initiallyLoaded: Boolean;
	temperatureUnit: string;
	[x:string]: any;
	timezoneChanged?: any;
}

interface WidgetControlProps {
	onEdit: () => void;
	onDelete: () => void;
	onFilter: () => void;
	onExport: () => void;
	userRole: string;
	isCreate: boolean;
	isRead: boolean;
	isUpdate: boolean;
	isDelete: boolean;
	assetDetails?: any;
}

const WidgetHeaderNew: React.FC<WidgetControlProps & WidgetHeaderProps> = (
	props
) => {

	let sysSetting = JSON.parse(props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'Enable Timescale Search Mode')[0].settingSpecs)

	const isNotMapWidget = !props.widgetState.type.includes('map') ? true : false 
	const isBatterySignal = ShowFilters(["battery level"], ["%"])
	const [timeRangeValue, setTimeRangeValue] = useState<string>(Array.isArray(props.zoomedTime) ? `${props.zoomedTime[0].format('MM/DD/YY HH:mma')} - ${props.zoomedTime[1].format('MM/DD/YY HH:mma')}` : props.zoomedTime);
	const [exportData, setExportData] = useState<boolean>(false);
	const [granularity, setGranularity] = useState<string>(isNotMapWidget ? "Automatic" : "Raw Data");
	const [granularityDisplay, setGranularityDisplay] = useState<string>(isNotMapWidget ? "30 Minute" : "Raw Data");
	const [aggregation, setAggregation] = useState<string>("Max");
	const [customFilters, setCustomFilters] = useState<string[]>([]); //["Hide Zeros"]
	const [rangeSelectorVisible, setRangeSelectorVisible] = useState<boolean>(false);
	const [rangeDisplayName, setRangeDisplayName] = useState<string>(`${timeRangeValue}${isNotMapWidget ? ` (${granularityDisplay} | ${aggregation})` : `` }`);
	const [customDateRange, setCustomDateRange] = useState<any>(props.zoomedTime);
	const [availableGranularity, setAvailableGranularity] = useState<any>(getAvailableGranularity(props.zoomedTime, 'Latest 24 hours', sysSetting.applyLimits));
	const [sysTimescaleSetting, setSysTimescaleSetting] = useState<any>(sysSetting);
	const [originalTimescaleData, setOriginalTimescaleData] = useState<any>();
	const [timescaleTimezone, setTimescaleTimezone] = useState<any>(props.state.timezone.value);
	const [widgetsSignalsData, setWidgetsSignalsData] = useState<LineWidgetSignalsConfigs[]>();

	const temperatureFUnitsArray = ['°F', 'Deg F', 'Degrees F'];
	const temperatureCUnitsArray = ['°C', 'Deg C', 'Degrees C'];
	useEffect(() => {
		if (props.initiallyLoaded) {
			refreshChart()
		}
	}, [props.refreshData])

	useEffect(() => {
		if (props.initiallyLoaded) {
			setCustomDateRange([props.zoomedTime[0], props.zoomedTime[1]])
			setTimeRangeValue('Custom')
			// const diffInHours = props.zoomedTime[1].diff(props.zoomedTime[0], 'hours')
			// console.log('diffInHours: ' + diffInHours)
		}

		setGranularity('Automatic')
		assignFilterDisplayAndReloadData(props.zoomedTime, Array.isArray(props.zoomedTime) ? 'Custom' : props.zoomedTime, aggregation, granularity, isNotMapWidget, 'Automatic', props.initiallyLoaded ? 'user_zoomed' : 'initial_loading')
		props.setInitiallyLoaded(true)
	}, [props.zoomedTime])

	


	useEffect(() => {
		
		let assetTimezone = false;
		
		if (timescaleTimezone != props.state.timezone.value) {
			if (props.state.timezone.value == "asset" && props.state.timezone.isGpsSignalAvailable) {
				if(props.state.timezone.coordinates.latitude && props.state.timezone.coordinates.longitude){
					assetTimezone = true;
				}

				let convertedData=convertTimestamp(originalTimescaleData,assetTimezone,widgetsSignalsData, props.initiallyLoaded ? 'user_zoomed' : 'initial_loading', granularityDisplay === 'Click to Filter' ? 'Raw Data' : granularityDisplay.includes('1 Day') ? '1 Day' : findAggAbbrev(aggregation));
				setTimescaleTimezone(props.state.timezone.value);
				props.setData(convertedData[0])
				if (convertedData[1].size > 0) {
					props.setRightAxisData(convertedData[1])
				}
				
			} else {
				
				setTimescaleTimezone(props.state.timezone.value);
				if (originalTimescaleData) {
					let convertedData=convertTimestamp(originalTimescaleData,assetTimezone,widgetsSignalsData, props.initiallyLoaded ? 'user_zoomed' : 'initial_loading', granularityDisplay === 'Click to Filter' ? 'Raw Data' : granularityDisplay.includes('1 Day') ? '1 Day' : findAggAbbrev(aggregation));
					props.setData(convertedData[0])
					if (convertedData[1].size > 0) {
						props.setRightAxisData(convertedData[1])
					}
					
				}
			}
		}
		
	},[props.state.timezone.value]);	

	useEffect(() => {
		isBatterySignal ? setCustomFilters(["Hide Zeros"]) : setCustomFilters([])
	}, [isBatterySignal])
	
	function ShowFilters(filterSignals: String[], filterUnits: String[]): Boolean {
		const { data, assetDetails } = props;
		const { datapoints } = assetDetails;
		const signalIds = data.keys();
		const collections = JSON.parse(datapoints);

		let include = false;
		let signal = signalIds.next().value;
		while (signal) {
			collections?.forEach((c: any) => c.values?.forEach((v: any) => {
			if (v._id == signal && filterSignals.includes(v.label.toLowerCase()) && filterUnits.includes(v.unit)) {
			  include = true;
			}
		  }))
	
		  signal = signalIds.next().value;
		}
		return include;
	}
	

	function confirm(e: any) {
		props.onDelete();
	}
	function cancel(e: any) { }

	function switchSearchMode(e: any) {
		props.setNewWidgetHeader(!props.newWidgetHeader);
	}


	function passWidgetFiltersToChart(aggregate:string, granularity: string ) {
		props.filterSetting({aggregate: aggregate, granularity: granularity});
	}

	const refreshChart = () => {
		assignFilterDisplayAndReloadData(customDateRange, timeRangeValue, aggregation, granularity, isNotMapWidget, granularity, Array.isArray(props.zoomedTime) ? 'user_zoomed' : granularityDisplay === 'Click to Filter' ? 'initial_loading' : 'user_filter')
	};

	const onVisibleChange = (visible: any) => {
		setRangeSelectorVisible(visible);
	};

	const convertIfTemperatureUnits = (signalValue: any, signalUnit: string) => {

		if (typeof signalUnit === 'string' && (temperatureFUnitsArray.some(substring => signalUnit.includes(substring)) || temperatureCUnitsArray.some(substring => signalUnit.includes(substring)) || signalUnit === 'C' || signalUnit === 'F') && isNumber(signalValue)) {
			if (
				(temperatureFUnitsArray.some(substring => signalUnit.includes(substring)) || signalUnit === 'F') &&
				props.temperatureUnit != null &&
				props.temperatureUnit === "°C"
			) {
				signalValue = ((signalValue - 32) * 5) / 9;
			}
			if (
				(temperatureCUnitsArray.some(substring => signalUnit.includes(substring)) || signalUnit === 'C') &&
				(props.temperatureUnit === "°F" ||
					props.temperatureUnit == null ||
					props.temperatureUnit === undefined)
			) {
				signalValue = (signalValue * 9) / 5 + 32;
			}
		
			return signalValue
		}

		return signalValue


	};

	const convertTimestamp = (data: any, assetTimezone: any, widgetsSignals: any, loadingType?: any, rangeTypeParam?: string, aggType?: string) => {
		
		let respData: any = []
		let indexMappingOfAggRespArray: string | number = 'value'

		widgetsSignals.forEach((SignalDetail: any) => {
			respData[SignalDetail['signalId']] = []
			
			data?.forEach((response: any) => {
				// check if collection is contains the signal
				if (response.config.url.split('/')[8] === SignalDetail['collectionId']) {
					response.data?.data?.data?.map((dataValue: any) => {
						let jsonValue = JSON.parse(dataValue.value);


						if (jsonValue[SignalDetail['signalId']] != null) {

							// Finding the correct key for the required value
							if (rangeTypeParam === '1 Day' && isNotMapWidget && loadingType !== 'initial_loading' && !dataValue.timestamp.includes('Z')) {
								switch (SignalDetail['agg']) {
									case "min":
										indexMappingOfAggRespArray = 0;
										break;
									case "max":
										indexMappingOfAggRespArray = 1;
										break;
									case "avg":
										indexMappingOfAggRespArray = 2;
										break;
									case "count":
										indexMappingOfAggRespArray = 4;
										break;
									case "first":
										indexMappingOfAggRespArray = 5;
										break;
									case "last":
										indexMappingOfAggRespArray = 6;
										break;
									default:
										indexMappingOfAggRespArray = SignalDetail['agg'];
								}
							} else if (loadingType === 'initial_loading' || rangeTypeParam === 'Raw Data' || !isNotMapWidget || rangeTypeParam === 'Data Export' || dataValue.timestamp.includes('Z')) {
								indexMappingOfAggRespArray = 'value'
							} else {
								indexMappingOfAggRespArray = SignalDetail['agg']
							}

							let timestamp : any = "2025-01-01T00:00:00.000Z" // Default fallback value
							
							if (rangeTypeParam === '1 Day' && isNotMapWidget && !dataValue.timestamp.includes('Z')) {
								let isoDateTimeString = dataValue.timestamp.replace(' ', 'T') + ':00';
								// const date = new Date(isoDateTimeString);
								// timestamp = date.toISOString();
								
								// Fix for local run								
								try{
									const date = new Date(isoDateTimeString);
									if (isNaN(date.getTime())) {
										throw new Error("Invalid Date");
									}
									timestamp = date.toISOString();
								}
								catch(error){
									console.error("Error parsing date:", error);
									// Fix the invalid ISO string by removing the extra ":00" in the time zone
									isoDateTimeString = isoDateTimeString.replace(/(\+\d{2}:\d{2}):00$/, '$1');
									console.log("Corrected ISO string:", isoDateTimeString);

									const date = new Date(isoDateTimeString);
									if (!isNaN(date.getTime())) {
										timestamp = date.toISOString();
									} else {
										console.error("Failed to parse corrected date string.");
									}
								}								
							} else {
								timestamp = isNotMapWidget ?
									(assetTimezone ?
										Date.parse(new Date(dataValue.timestamp).toLocaleString("en-US", { timeZone: tzlookup(props.state.timezone.coordinates.latitude, props.state.timezone.coordinates.longitude) })) :
										Date.parse(dataValue.timestamp)) :
									(assetTimezone ?
										new Date(dataValue.timestamp).toLocaleString("en-US", { timeZone: tzlookup(props.state.timezone.coordinates.latitude, props.state.timezone.coordinates.longitude) }) :
										moment(dataValue.timestamp).format('llll'))
							}
							
							
							// Getting the correct value (Fixing avg aggregation issue for data aggregates of 1min/5min/1hour after 2023-07-17) Also this will correct for sum value. In DB there is a typo which avg and sum aggregates where sum is named as avg and avg does not exist but avg is the sum/count.
							var timestampDate = new Date(timestamp);
							var comparisonDate = new Date("2023-07-17");

							if ((rangeTypeParam !== '1 Day' && rangeTypeParam !== 'Raw Data') && timestampDate > comparisonDate && (aggType === 'avg' || aggType === 'sum') && isNotMapWidget) {

								let signalValue = aggType === 'avg' ? parseFloat(jsonValue[SignalDetail['signalId']]['avg']) / parseFloat(jsonValue[SignalDetail['signalId']]['count']) : parseFloat(jsonValue[SignalDetail['signalId']]['avg'])
								
								signalValue = convertIfTemperatureUnits(signalValue, SignalDetail.units)

								respData[SignalDetail['signalId']].push({
									timestamp: timestamp,
									value: signalValue
								})

							} else if (aggType === 'difference' && rangeTypeParam !== 'Raw Data') {
								
								if (rangeTypeParam === '1 Day') {
									
									let signalValue = parseFloat(jsonValue[SignalDetail['signalId']][6]) - parseFloat(jsonValue[SignalDetail['signalId']][5])

									signalValue = convertIfTemperatureUnits(signalValue, SignalDetail.units)

									respData[SignalDetail['signalId']].push({
										timestamp: timestamp,
										value: signalValue
									})

								} else {
									
									let signalValue = parseFloat(jsonValue[SignalDetail['signalId']]['last']) - parseFloat(jsonValue[SignalDetail['signalId']]['first'])

									signalValue = convertIfTemperatureUnits(signalValue, SignalDetail.units)

									respData[SignalDetail['signalId']].push({
										timestamp: timestamp,
										value: signalValue
									})

								}

							} else {
								let signalValue = parseFloat(jsonValue[SignalDetail['signalId']][indexMappingOfAggRespArray])

								signalValue = convertIfTemperatureUnits(signalValue, SignalDetail.units)

								respData[SignalDetail['signalId']].push({
									timestamp: timestamp,
									value: signalValue
								})
							}
							
						}
						

						

						
					})
				}
				
			});
		})

		let dataSet = new Map<SignalId, SignalDatapoint[]>();
		let dataSetRightAxis = new Map<SignalId, SignalDatapoint[]>();
		widgetsSignals.forEach((SignalDetail: any) => {
			if (SignalDetail.axis == 'left')
				dataSet.set(SignalDetail['signalId'], respData[SignalDetail['signalId']]);
			if (SignalDetail.axis == 'right')
				dataSetRightAxis.set(SignalDetail['signalId'], respData[SignalDetail['signalId']]);
		});

		return [dataSet, dataSetRightAxis];

	}


	// Function to format timestamp to 'yyyy-MM-dd hh:mm:ss'
    const formatTimestamp = (timestamp: any) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};
	
	const adjustSheetName = (name:string) => {
        return name.length > 31 ? name.substr(0, 31) : name;
    };

    // Function to convert dataSet to Excel format
    const dataSetToWorkbook = (resDataSet: any, widgetsSignals: any, uniqueSignalCollectionIds: any) => {
		const wb = XLSX.utils.book_new();
		
		// console.log('dataSetToWorkbook')
		// console.log(props)
		// console.log(props.assetDetails.template.signalCollections)
		// console.log(uniqueSignalCollectionIds)
		let collectionDetails:any = []
		let dataSet:any = []
		props.assetDetails.template.signalCollections.map((collection: any) => {
			uniqueSignalCollectionIds.map((id: any) => {
				if (id.includes(collection.id)) {
					collectionDetails.push(collection)
				}
			})
		})

		// console.log("collectionDetails")
		// console.log(collectionDetails)

		const now = moment();
		const currentTimezone = moment.tz.guess();
		const timezoneAbbr = now.tz(currentTimezone).format('z');
		
			
		collectionDetails?.forEach((CollectionDetail: any) => {

			dataSet[CollectionDetail['id']] = []

			resDataSet?.forEach((response: any) => {
				// check if collection is contains the signal
				if (response.config.url.split('/')[8] === CollectionDetail['id']) {
					response.data?.data?.data?.map((dataValue: any) => {
						let jsonValue = JSON.parse(dataValue.value);

						let dataToPush:any = {};
						const formatDateWithMilliseconds = (dateString: string): string => {
							const date = new Date(dateString);
							const year = String(date.getFullYear());
							const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
							const day = String(date.getDate()).padStart(2, '0');
							const hours = String(date.getHours()).padStart(2, '0');
							const minutes = String(date.getMinutes()).padStart(2, '0');
							const seconds = String(date.getSeconds()).padStart(2, '0');
							const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
							return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
					 };
					
						// Iterate through jsonValue to append all titles and values
						Object.keys(jsonValue).forEach((key) => {
							const signalDetail = jsonValue[key];
							//dataToPush['Time Stamp ('+timezoneAbbr+')'] =  moment.utc(dataValue['timestamp']).local().format('YYYY-MM-DDTHH:mm:ss');
							dataToPush['Time Stamp (' + timezoneAbbr + ')'] = formatDateWithMilliseconds(dataValue['timestamp']);
							dataToPush[`${signalDetail['name']} ${signalDetail['uom'] ? " ("+signalDetail['uom']+")" : ""}`] = parseFloat(signalDetail['value']);
						});

						dataSet[CollectionDetail['id']].push(dataToPush)
					})
				}
				
			});
		});

		// console.log("dataSet")
		// console.log(dataSet)
		
		collectionDetails?.forEach((CollectionDetail: any) => {
			const ws = XLSX.utils.json_to_sheet(dataSet[CollectionDetail['id']]);
			XLSX.utils.book_append_sheet(wb, ws, adjustSheetName(CollectionDetail['name']));
		});
		
		

        return wb;
    };

    // Function to trigger the download of the Excel file
    const downloadExcel = (dataSet:any, startDate:any, endDate:any, interval:any, reading:any, widgetsSignals:any, uniqueSignalCollectionIds:any) => {
		const wb = dataSetToWorkbook(dataSet, widgetsSignals, uniqueSignalCollectionIds);
		// console.log("startDate, endDate")
		// console.log(startDate, endDate)
		// console.log("wb")
		// console.log(wb)
        const fileName = `${props.assetDetails.mastertag}_${startDate.replaceAll(':', '_').replaceAll('-', '_').replaceAll(' ', '_')} - ${endDate.replaceAll(':', '_').replaceAll('-', '_').replaceAll(' ', '_')}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

	const reloadData = (interval: string, reading: string, startDate: string, endDate: string, customFiltersTmp: string[], loadingType: string, rangeTypeParam?: string) => {

		// check if time range is more than 2 month for map widget
		if (!isNotMapWidget && moment(endDate).diff(moment(startDate), 'months', true) > 2) {
			message.info("Max range allowed for map widgets is 2 months");
			return
		}

		const datapointArray: SignalCollectionId[] = [];
		let widgetsSignals:LineWidgetSignalsConfigs[] = [];
		let assetTimezone: boolean = false;

		if (!loadingType) {
			loadingType = 'initial_loading'
		}

		if(props.state.timezone.value=="asset" && props.state.timezone.isGpsSignalAvailable){
			//if(props.gpslocation.latitude && props.gpslocation.longitude){
			if(props.state.timezone.coordinates.latitude && props.state.timezone.coordinates.longitude){
					assetTimezone = true;
			}
		}
 
		//find the signal collection id to make an api request
		props.widgetState.signals.forEach((si:any) => {
			const datapoint = props.template.signalCollections.filter((s:any) =>
				s.signals.find((sg:any) => sg.id === (si.id ? si.id : si))
			)[0];

			if (datapoint && datapoint["id"]) {
				const signalInfo = datapoint.signals.filter((signalInfo: any) => signalInfo.id === si.id || signalInfo === si)
				datapointArray.push(props.assetDetails.orgId + '~' + props.assetId + '~' + datapoint.id);
				widgetsSignals.push({"collectionId": datapoint.id, "signalId": si.id ? si.id : si, "agg": reading, 'axis': 'left', units: signalInfo.length ? signalInfo[0].units : '' });
			}
		});

		if (props.widgetState?.rightYAxisSignals) {
			props.widgetState.rightYAxisSignals.forEach((si:any) => {
				const datapoint = props.template.signalCollections.filter((s:any) =>
					s.signals.find((sg:any) => sg.id === (si.id ? si.id : si))
				)[0];
	
				if (datapoint && datapoint["id"]) {
					const signalInfo = datapoint.signals.filter((signalInfo: any) => signalInfo.id === si.id || signalInfo === si)
					datapointArray.push(props.assetDetails.orgId + '~' + props.assetId + '~' + datapoint.id);
					widgetsSignals.push({"collectionId": datapoint.id, "signalId": si.id ? si.id : si, "agg": reading, 'axis': 'right', units: signalInfo.length ? signalInfo[0].units : '' });
				}
			});
		}
	
		//stop the repetition of datapoints
		const uniqueSignalCollectionId = datapointArray.filter((elem, pos) => {
			return datapointArray.indexOf(elem) === pos;
		})

		let requiredSignals = widgetsSignals.map((signal:any) => signal.signalId)
		
		if (exportData) {
			
			loadingIndicatorStart();

			setWidgetsSignalsData(widgetsSignals)

			Api.loadSignalsDataTimescale(interval,reading, uniqueSignalCollectionId, moment(startDate), moment(endDate), 'data_export', customFiltersTmp, requiredSignals)
			.then((resp: any) => {
				
				if (resp[0].hasOwnProperty("response")) {
					if (resp[0].response.data.status === 'fail') {
						message.warning(resp[0].response.data.errors[0]['message']);
					}
				} else {
					setOriginalTimescaleData(resp)
					downloadExcel(resp, startDate, endDate, interval, reading, widgetsSignals, uniqueSignalCollectionId);
				}

			})
			.catch((err) => {
				console.log(err)
				errorResponse(null, err);
				message.error(err.message);
			})
			.finally(()=> { 
				props.setLoading(false);
				loadingIndicatorEnd()
				setExportData(false);
			});

		} else {
			setWidgetsSignalsData(widgetsSignals)
			Api.loadSignalsDataTimescale(interval, reading, uniqueSignalCollectionId, moment(startDate), moment(endDate), !isNotMapWidget && props.initiallyLoaded ? 'map_view' : loadingType, customFiltersTmp, requiredSignals)
			.then((resp: any) => {
				if (resp[0].hasOwnProperty("response")) {
					if (resp[0].response.data.status === 'fail') {
						message.warning(resp[0].response.data.errors[0]['message']);
					}
				} else {
					setOriginalTimescaleData(resp)
					let dataSet = convertTimestamp(resp, assetTimezone, widgetsSignals, loadingType, rangeTypeParam, reading)

					props.setData(dataSet[0])
					if (dataSet[1].size > 0) {
						props.setRightAxisData(dataSet[1])
					}
				}
			})
			.catch((err) => {
				console.log(err)
				//console.log(err.response)
				// errorResponse(null, err);
				// message.error(err.message);
			})
			.finally(()=> { 
				props.setLoading(false);
				loadingIndicatorEnd()
			});

			props.setLoading(false);
			loadingIndicatorEnd()

		}
		

	}

	const assignFilterDisplayAndReloadData = (DataSearchRangeInput: any, timeRangeTextInput: any, aggregationInput: any, granularityInput: any, isNotMapWidgetInput: any, GranularityInput: any, loadingType: string) => {
		let resultValues = getTimescaleFilterText(DataSearchRangeInput, timeRangeTextInput, aggregationInput, granularityInput, isNotMapWidgetInput, GranularityInput)

		setGranularityDisplay(resultValues.granularityDisplay)

		if (resultValues.shouldQuery) {
			let rangeTypeParam = 'N/A'
			const diffInHours = resultValues.DataSearchRange[1].diff(resultValues.DataSearchRange[0], 'hours')
			rangeTypeParam = diffInHours >= 719 ? '1 Day' : diffInHours <= 2 ? 'Raw Data' : 'N/A'

			reloadData(isNotMapWidget ? resultValues.granularityTmp : 'Raw Data', isNotMapWidget ? findAggAbbrev(aggregation) : 'avg', resultValues.DataSearchRange[0].format('YYYY-MM-DD HH:mm'), resultValues.DataSearchRange[1].format('YYYY-MM-DD HH:mm'), customFilters, loadingType, rangeTypeParam)
			setRangeDisplayName(`${resultValues.timeRange}   ${isNotMapWidgetInput ? `(${resultValues.granularityDisplay})` : resultValues.granularityDisplay === 'Click to Filter' ? 'Click to Filter' : ''}`)

			let filterSetting = resultValues.granularityDisplay === 'Click to Filter' ? ['Raw Data', 'Raw Data'] : resultValues.granularityDisplay.split(' | ')
			passWidgetFiltersToChart(filterSetting[1], filterSetting[0])
		} else {
			message.info("Please select time range")
		}

	}

	const findAggAbbrev = (aggregationString: string) => {
		switch (aggregationString) {
			case 'Max':
				return 'max'
			case 'Min':
				return 'min'
			case 'Average':
				return 'avg'
			case 'Sum':
				return 'sum'
			case 'Difference':
				return 'difference'
			case 'Last':
				return 'last'
			case 'First':
				return 'first'
			case 'Count':
				return 'count'
			default:
				return 'max'
		}
	}

	const onChangeTimeRange = (e: any) => {

		setAvailableGranularity(getAvailableGranularity([moment().subtract(3, "month").startOf('month'), moment().subtract(1, "month").endOf('month')], e.target.value, sysTimescaleSetting.applyLimits))
		setGranularity('Automatic')
		if (e.target.value != 'Custom') {
			setTimeRangeValue(e.target.value);
		} else {
			setCustomDateRange([moment().subtract(1, "month").startOf('month'), moment().subtract(1, "month").endOf('month')])
			setTimeRangeValue(e.target.value);
		}
	};

	const dateRangeChanged = (date:any, dateString:any) => {
		const diffInHours = date[1].diff(date[0], 'hours')
		setAvailableGranularity(getAvailableGranularity([date[0], date[1]], 'Custom', sysTimescaleSetting.applyLimits))
		setCustomDateRange([date[0], date[1]])
	}

	const onChangeExport = (e: any) => {
		setExportData(e.target.value);
	};

	function granularityChanged(value: string) {	
		setGranularity(value)
	}

	function aggregationChanged(value: string) {
		setAggregation(value)
	}

	function customFiltersChanged(value: string[]) {
		setCustomFilters(value)
	}

	const getData = () => {
		assignFilterDisplayAndReloadData(customDateRange, timeRangeValue, aggregation, granularity, isNotMapWidget, granularity, 'user_filter')
		onVisibleChange(false)
	};

	const content = (
		<>
			<Row style={{ maxWidth: isNotMapWidget ? "440px" : "280px" }}>
				<Col span={isNotMapWidget ? 16 : 24 }>
					<span>Select time range:</span>
					<br />
					<Row style={{ marginTop: "3px", marginBottom: isNotMapWidget ? "0px" : "10px" }}>
						<Col span={12}>
							<Radio.Group onChange={onChangeTimeRange} value={timeRangeValue}>
								<Space className="radioSpacing" direction="vertical">
									<Radio checked={timeRangeValue == "Latest 30 minutes"} value="Latest 30 minutes">
										Latest 30 minutes
									</Radio>
									<Radio checked={timeRangeValue == "Latest hour"} value="Latest hour">
										Latest hour
									</Radio>
									<Radio checked={timeRangeValue == "Latest 4 hours"} value="Latest 4 hours">
										Latest 4 hours
									</Radio>
									<Radio checked={timeRangeValue == "Latest 12 hours"} value="Latest 12 hours">
										Latest 12 hours
									</Radio>
									<Radio checked={timeRangeValue == "Latest 24 hours"} value="Latest 24 hours">
										Latest 24 hours
									</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col style={{ paddingLeft: "10px" }} span={12}>
							<Radio.Group onChange={onChangeTimeRange} value={timeRangeValue}>
								<Space className="radioSpacing" direction="vertical">
									<Radio checked={timeRangeValue == "Latest 48 hours"} value="Latest 48 hours">
										Latest 48 hours
									</Radio>
									<Radio checked={timeRangeValue == "Latest 3 days"} value="Latest 3 days">
										Latest 3 days
									</Radio>
									<Radio checked={timeRangeValue == "Latest 7 days"} value="Latest 7 days">
										Latest 7 days
									</Radio>
									<Radio checked={timeRangeValue == "Latest 30 days"} value="Latest 30 days">
										Latest 30 days
									</Radio>
									<Radio checked={timeRangeValue == "Custom"} value="Custom">
										Custom
									</Radio>
								</Space>
							</Radio.Group>
						</Col>
						{ timeRangeValue == "Custom" &&
							<Col style={{ paddingTop: "10px" }} span={isNotMapWidget ? 23 : 24}>
								<RangePicker
									className="pickerField"
									showTime
									size='middle'
									allowClear={false}
									disabledDate={(current) => current.isBefore(moment().subtract(1,"year").startOf('year'))}
									ranges={{
										'Today': [moment().startOf('day'), moment()],
										'Yesterday': [moment().subtract(1, "day").startOf('day'), moment().subtract(1, "day").endOf('day')],
										// 'Last 24 Hours': [moment().subtract(24, "hours"), moment()],
										// 'Last 48 Hours': [moment().subtract(24, "hours"), moment()],
										'This Week': [moment().startOf('week'), moment()],
										'Last Week': [moment().subtract(1, "week").startOf('week'), moment().subtract(1, "week").endOf('week')],
										'Last 14 Days': [moment().subtract(13, "days").startOf('day'), moment()],
										'This Month': [moment().startOf('month'), moment()],
										'Last Month': [moment().subtract(1, "month").startOf('month'), moment().subtract(1, "month").endOf('month')],
										'Last 3 Month': [moment().subtract(3, "month").startOf('month'), moment().subtract(1, "month").endOf('month')],
										'Last 12 Months': [moment().subtract(1, 'year'), moment()],
										'Year To Date': [moment().startOf('year'), moment()],
										// 'Last Year': [moment().subtract(1, "year").startOf('year'), moment().subtract(1, "year").endOf('year')],
									}}
									onChange={dateRangeChanged}
									value={customDateRange}
									defaultValue={[moment(), moment().subtract(1, "hour")]}
									format='MM/DD/YY HH:mm'
								/>
							</Col>
						}
					</Row>
				</Col>

				{ isNotMapWidget &&
					<Col style={{ borderLeft: "1px solid #cfcfcf", paddingLeft: "5px" }} span={8}>
						<Row>
							{/* <Col style={{ marginTop: "3px", marginBottom: "5px" }} span={24}>
								<span>Time granularity:</span>
								<br />
								<Select
									size="small"
									defaultValue={granularity}
									style={{ width: "100%" }}
									listHeight={350}
									onChange={granularityChanged}
									value={granularity}
								>
									<Option value="Automatic">Automatic</Option>
									<Option disabled={(availableGranularity - 3) >= 1} value="1 Minute">1 Minute</Option>
									<Option disabled={(availableGranularity - 3) >= 2} value="5 Minute">5 Minutes</Option>
									<Option disabled={(availableGranularity - 3) >= 3} value="15 Minutes">15 Minutes</Option>
									<Option disabled={(availableGranularity - 2) >= 4} value="30 Minutes">30 Minutes</Option>
									<Option disabled={(availableGranularity - 1) >= 5} value="1 Hour">1 Hour</Option>
									<Option disabled={(availableGranularity - 1) >= 5} value="3 Hours">3 Hours</Option>
									<Option disabled={(availableGranularity - 1) >= 5} value="6 Hours">6 Hours</Option>
									<Option disabled={(availableGranularity - 1) >= 6} value="12 Hours">12 Hours</Option>
									<Option disabled={(availableGranularity - 1) >= 7} value="1 Day">1 Day</Option>
									<Option disabled={(availableGranularity - 1) >= 0} value="Raw Data">Raw Data</Option>
								</Select>
							</Col> */}
							
							<Col style={{ marginTop: "15px" }} span={24}>
								<span >Aggregation:</span>
								<br />
								<Select
									size="small"
									defaultValue={aggregation}
									style={{ width: "100%", marginTop: "3px" }}
									onChange={aggregationChanged}
									disabled={granularity == 'Raw Data'}
									value={aggregation}
								>
									<Option value="Max">Max</Option>
									<Option value="Min">Min</Option>
									<Option value="Average">Average</Option>
									<Option value="Sum">Sum</Option>
									<Option value="Difference">Difference</Option>
									<Option value="Last">Last</Option>
									<Option value="First">First</Option>
									<Option value="Count">Count</Option>
								</Select>
							</Col>
						
							<Col style={{ marginTop: "15px" }} span={24}>
								<Radio.Group size="small" style={{ lineHeight: "-10px", marginBottom: "0px" }} onChange={onChangeExport} value={exportData}>
									<Space className="radioSpacing" direction="vertical">
										<Radio style={{ marginBottom: "0px" }} checked={exportData == false} value={false}>
											Visualization
										</Radio>
										<Radio style={{ marginBottom: "0px" }} checked={exportData} value={true}>
											Export (Raw Data)
										</Radio>
									</Space>
								</Radio.Group>
							</Col>
						</Row>
					</Col>
				}

				{!isNotMapWidget &&
					<Col style={{ borderTop: "1px solid #cfcfcf", paddingTop: "5px", paddingBottom: "5px" }} span={24}>
						<Row>
							<Col style={{ marginTop: "5px" }} span={24}>
								<Radio.Group size="small" style={{ lineHeight: "-10px", marginBottom: "0px" }} onChange={onChangeExport} value={exportData}>
									<Space className="radioSpacing" direction="horizontal">
										<Radio style={{ marginBottom: "0px" }} checked={exportData == false} value={false}>
											Visualization
										</Radio>
										<Radio style={{ marginBottom: "0px" }} checked={exportData} value={true}>
											Export (Raw Data)
										</Radio>
									</Space>
								</Radio.Group>
							</Col>
						</Row>
					</Col>
				}

			</Row>

			{
				isNotMapWidget && ShowFilters(["battery level"], ["%"]) &&
				<Row style={{ borderTop: "1px solid #cfcfcf", marginTop: "10px" }}>
					<Col style={{ marginTop: "5px" }} span={24}>
						<Collapse className="customCollapse" >
							<Panel header={<span className="collapseTitle"> Advanced Filters </span>} key="1" >
								<span >Visualization Filters:</span><br />
								<Select 
									mode="tags"
									defaultValue={customFilters}
									onChange={customFiltersChanged}
									style={{ width: "100%", maxWidth: '420px', position: "inherit" }}
									placeholder="Select Tags"
										
									size="small"
									value={customFilters}
								>
									<Option value="Hide Zeros">Hide Zeros</Option>
									{/* <Option value="Hide Non-Incremental Values">Hide Non-Incremental Values</Option> */}
								</Select>
							</Panel>
						</Collapse>
							
						{/* <span >Additional Visualization Filters:</span>
						<br />
						<Select 
							mode="tags"
							defaultValue={customFilters}
							onChange={customFiltersChanged}
							style={{ width: '100%' }}
							placeholder="Select Tags"
							size="small"
							value={customFilters}
						>
							<Option value="Hide Zeros">Hide Zeros</Option>
						</Select> */}
					</Col>
				</Row>
			}
			
			<Row style={{ borderTop: "1px solid #cfcfcf", marginTop: "5px" }}>
				<Col style={{ textAlign: "right", marginTop: "5px" }} span={24}>
					<Button size="small" type="primary" onClick={() => getData()}>
						Apply
					</Button>
					<Button
						style={{ marginLeft: "10px" }}
						size="small"
						type="default"
						onClick={() => onVisibleChange(!rangeSelectorVisible)}
					>
						Cancel
					</Button>
				</Col>
			</Row>
		</>
	);

	return (
		<div style={{ width: "100%" }}>
			<Space>
				{props.isUpdate ? (
					<Tooltip title="Edit Widget Properties">
						<EditOutlined onClick={props.onEdit} />
					</Tooltip>
				) : (
					""
				)}

				{ props.isDelete ? (
					<Popconfirm
						title="Are you sure to delete this widget?"
						onConfirm={confirm}
						onCancel={cancel}
						okText="Yes"
						cancelText="No"
					>
						<Tooltip title="Delete Widget">
							<DeleteOutlined />
						</Tooltip>
					</Popconfirm>
				) : (
					""
				)}

				{ props.isLoading ? (
					<Tooltip title="Refresh Widget Data (Disabled)">
						<LoadingOutlined />
					</Tooltip>
				) : (
					<Tooltip title="Refresh Widget Data">
						<ReloadOutlined onClick={(e) => refreshChart()} />
					</Tooltip>
				)}

				<Popover
					placement="bottomRight"
					content={content}
					trigger="click"
					visible={rangeSelectorVisible}
					onVisibleChange={onVisibleChange}
				>
					{ props?.widgetState?.sizeX && props?.widgetState?.sizeX > 1 ?
						<Tooltip overlayStyle={{ maxWidth: '550px', backgroundColor: "white", color: '#cfcfcf'}} title={rangeDisplayName}>
							<Button style={{maxWidth: "300px", textOverflow: "ellipsis", overflow: "hidden"}} type="default" size="small">
								<span style={{textOverflow: "ellipsis", overflow: "hidden", maxWidth: "300px"}}>{rangeDisplayName}</span>
							</Button>
						</Tooltip>
						:
						<Tooltip overlayStyle={{ maxWidth: '550px', backgroundColor: "white", color: '#cfcfcf'}} title={rangeDisplayName}><FilterOutlined/></Tooltip>
					}
				</Popover>
				
				{/* <Popconfirm
					title={
						<>
							<span style={{ marginLeft: "-20px" }}>
								Switch to classic search mode?{" "}
							</span>
							<br />
							<span
								style={{
									fontSize: "12px",
									color: "#B9B9B9",
									marginLeft: "-20px",
								}}
							>
								Note: You may switch back to the new search mode at anytime!
							</span>
						</>
					}
					icon={<div style={{ display: "none" }}></div>}
					onConfirm={switchSearchMode}
					onCancel={cancel}
					okText="Yes"
					cancelText="No"
				>
					<Tooltip
						mouseEnterDelay={1}
						placement="bottom"
						title="Switch To Classic Search Mode! This will switch back to the classic search mode. You may switch back to the new search mode at anytime!"
					>
						<ArrowRightOutlined className="" style={{ color: "#bdbdbd" }} />
					</Tooltip>
				</Popconfirm> */}
			</Space>
		</div>
	);
};

export default WidgetHeaderNew;
