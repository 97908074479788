import React, { useEffect, useState } from "react";
import { Modal, Table, Checkbox, Space, Tooltip, Input, Select, Col, Row, Button, message, Divider, Popover, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { PhoneOutlined, MailOutlined, FileTextOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";

import { connect } from "react-redux";
import * as actions from "../actions";
import * as contractActions from "../../Admin/Contracts/actions";
import moment from "moment";

import { appConfigs } from "../../../utils/configurations";

import "../orgs.css";

const { Option } = Select;

interface Prop {
    getCustomerBucketDetails: Function,   
    getOrgServicePlanBucketByOrdId: Function,   
    getServicePlansList: Function,   
    transferServicePlanToOrg: Function,   
    returnServicePlanToTopParentCustomerOrg: Function,   
    context: any,
    assetsList: any,
    servicePlansList: any,
    customerBucketDetails: any,
    currentOrgDetails: any,
    getServicePlansComparisionSheet:any
}


const OrgContractsDetails: React.FC<Prop> = (props) => {

    const formRef = React.createRef<FormInstance>();
    const [showOrgZeros, setShowOrgZeros] = useState(false)
    const [POQuickMode, setPOQuickMode] = useState(false)
    const [showZeros, setShowZeros] = useState(false)
    const [contractsHistoryLoading, setContractsHistoryLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [currentOrgBucketDetails, setCurrentOrgBucketDetails] = useState([])

    const [GatewayContractsDetails, setGatewayContractsDetails] = useState<any>({billingContractHistory: [], renewalContractHistory: []})
    
    useEffect(() => {
        props.getServicePlansList()
        props.getServicePlansComparisionSheet();
        switchContractsHistoryLoading(true)
    }, [props.assetsList.assetMaint.record.orgId]);


    useEffect(() => {
        if (props.servicePlansList.length !== 0) {
            props.getCustomerBucketDetails(props.context.appContext.orgId)
            setCurrentOrgBucketDetails(props.getOrgServicePlanBucketByOrdId(props.currentOrgDetails.orgId, props.servicePlansList, orgContractsDetailsSuccess, orgContractsDetailsError))
        }
    }, [props.servicePlansList]);

    
    const orgContractsDetailsSuccess = (orgContractsDetailsSuccess: any) => {
        setCurrentOrgBucketDetails(orgContractsDetailsSuccess);
    };
    const orgContractsDetailsError = (errorMessage: string) => {
        message.error(errorMessage)
    };


    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleOk = () => {

        setIsModalVisible(false);

        formRef.current?.setFieldsValue({
            userConfirmed: true,
        })

        props.transferServicePlanToOrg(formRef.current?.getFieldsValue(), servicePlanAssignedSuccess, servicePlanAssignedError)

    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const toggleOrgZeros = () => {
        setShowOrgZeros(!showOrgZeros)
    }

    const isUUID = (str:any) => {
        const regexExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return regexExp.test(str);
    }

    const getAvailableQuantityByBucketID = (data:any, bucketId:any) => {
        for (let item of data) {
            if (item.partNumberBucketEntries) {
                for (let entry of item.partNumberBucketEntries) {
                    if (entry.bucketId === bucketId) {
                        return entry.availableQuantity;
                    }
                }
            }
        }
        return null;
    }
    


    const updateFormField = (value:any, fieldName:string) => {
        formRef.current?.setFieldsValue({
            [fieldName]: value
        });

        // props.customerBucketDetails[itemKey]?.partNumberBucketEntries[POEntry].availableQuantity
        if (isUUID(value)) {
            formRef.current?.setFieldsValue({
                'transferredQuantity': getAvailableQuantityByBucketID(props.customerBucketDetails, value)
            });
        }
        
    }


    const withBetweenPOandQuickMode = () => {
        resetFormErrors(null, formRef.current);
        formRef.current?.resetFields();
        setPOQuickMode(!POQuickMode)
    }

    const toggleZeros = () => {
        setShowZeros(!showZeros)
    }

    const switchContractsHistoryLoading = (status: boolean) => {
        setContractsHistoryLoading(status)
    }

    const onGetContractDetailsSuccess = (contractsDetails: any) => {
        if(contractsDetails){
            setGatewayContractsDetails({
                billingContractHistory: contractsDetails.billingContractHistory ? JSON.parse(contractsDetails.billingContractHistory) : [], 
                renewalContractHistory: contractsDetails.renewalContractHistory ? JSON.parse(contractsDetails.renewalContractHistory) : []
            })
        }

        switchContractsHistoryLoading(false)
    }
    const onGetContractDetailsFailure = (errorMessage: any) => {
        // console.log('errorMessage')
        // console.log(errorMessage)
    }


    const returnAvailableServicePlans = (ReturnServicePlanDetails: any) => {
        // console.log('ReturnServicePlanDetails')
        // console.log(ReturnServicePlanDetails)
        props.returnServicePlanToTopParentCustomerOrg(ReturnServicePlanDetails, returnSuccess, returnFailure)
    }

    const returnSuccess = (successMessage: any) => {
        message.success(successMessage)
        props.getCustomerBucketDetails(props.context.appContext.orgId)
        setCurrentOrgBucketDetails(props.getOrgServicePlanBucketByOrdId(props.currentOrgDetails.orgId, props.servicePlansList, orgContractsDetailsSuccess, orgContractsDetailsError))
    }
    const returnFailure = (defaultMessage: any, ServerMessage: any) => {
        if(ServerMessage?.includes('There is an active contract expiring on') || ServerMessage?.includes('There is a pending service plan')){
            setModalMessage(ServerMessage)
            showModal()
        } else {
            message.error(ServerMessage ? ServerMessage : defaultMessage, 7);
        }
    }

    const onFinishSubmitTransfer = (values: any) => {
        resetFormErrors(null, formRef.current); 
        let availableQty:number = 0;
        

        // console.log('onFinishSubmitTransfer');
        // console.log(props.customerBucketDetails)
        // console.log(formRef.current?.getFieldsValue())

        if (POQuickMode) {
            
            if (props.customerBucketDetails) {
                Object.keys(props.customerBucketDetails)?.map((itemKey: any) => {
                    if (props.customerBucketDetails[itemKey]?.servicePlanId === formRef.current?.getFieldValue('partNumberId')) {
                        availableQty = props.customerBucketDetails[itemKey]?.total_available_quantity
                    }
                })
            }
            
            if (formRef.current?.getFieldValue('transferredQuantity') > availableQty) {
                message.warning("Transferred quantity cannot be more than available quantity")
                return
            }
            
            formRef.current?.setFieldsValue({
                toOrgId: props.currentOrgDetails.orgId,
                fromOrgId: props.context.appContext.orgId,
                bucketId: "",
                userConfirmed: false,
            })

            if (formRef.current?.getFieldValue('transferredQuantity') === '' ||
                formRef.current?.getFieldValue('toOrgId') === '' ||
                formRef.current?.getFieldValue('fromOrgId') === '' ||
                formRef.current?.getFieldValue('partNumberId') === ''
            ) {
                message.error("Unable to assign service plan please contact system administrator", 7)
            } else {
                // console.log("formRef.current?.getFieldsValue()")
                // console.log(formRef.current?.getFieldsValue())

                props.transferServicePlanToOrg(formRef.current?.getFieldsValue(), servicePlanAssignedSuccess, servicePlanAssignedError)
            }
            
        } else {

            let bucketId:string = '';
            bucketId = formRef.current?.getFieldValue('partNumberId')

            Object.keys(props.customerBucketDetails)?.map((itemKey: any) => {
                Object.keys(props.customerBucketDetails[itemKey]?.partNumberBucketEntries)?.map((POEntry: any) => {
                    if (props.customerBucketDetails[itemKey]?.partNumberBucketEntries[POEntry].bucketId === bucketId) {
                        availableQty = parseInt(props.customerBucketDetails[itemKey]?.partNumberBucketEntries[POEntry].availableQuantity)
                    }
                })
            })

            // console.log(bucketId)
            // console.log(availableQty)
            // console.log(formRef.current?.getFieldValue('transferredQuantity'))
            if (formRef.current?.getFieldValue('transferredQuantity') > availableQty) {
                message.warning("Transferred quantity cannot be more than available quantity")
                return
            }

            formRef.current?.setFieldsValue({
                toOrgId: props.currentOrgDetails.orgId,
                fromOrgId: props.context.appContext.orgId,
                bucketId: bucketId,
                userConfirmed: false,
            })

            if( formRef.current?.getFieldValue('transferredQuantity') === '' ||
                formRef.current?.getFieldValue('toOrgId') === '' ||
                formRef.current?.getFieldValue('fromOrgId') === '' ||
                formRef.current?.getFieldValue('bucketId') === ''
            ){
                message.error("Unable to assign service plan please contact system administrator", 7)
            } else {
                props.transferServicePlanToOrg(formRef.current?.getFieldsValue(), servicePlanAssignedSuccess, servicePlanAssignedError)
            }
            

        }
    }

    const servicePlanAssignedSuccess = (data: any, resultMessage: any) => {
        
        message.success(resultMessage, 7);
        setContractsHistoryLoading(true)
        props.getCustomerBucketDetails(props.context.appContext.orgId)
        setCurrentOrgBucketDetails(props.getOrgServicePlanBucketByOrdId(props.currentOrgDetails.orgId, props.servicePlansList, orgContractsDetailsSuccess, orgContractsDetailsError))
        
    }

    const servicePlanAssignedError = (defaultMessage: any, ServerMessage: any) => {
        if(ServerMessage?.includes('There is an active contract expiring on') || ServerMessage?.includes('There is a pending service plan')){
            setModalMessage(ServerMessage)
            showModal()
        } else {
            message.error(ServerMessage ? ServerMessage : defaultMessage, 7);
        }
    }


    const customerBucketTableColumn = [
        {
            title: 'Service Plan Description',
            dataIndex: 'packageName',
            render: (text: string) => {
                text = text.replace(/\s*\(.*?\)$/, '');
                if (/Local Plan/gi.test(text)) 
                    {        
                         text = 'Local Plan (WiFi Only)'; 
                    }
    
                return text;
            },
        },
        {
            title: 'Service Plan Part Number',
            dataIndex: 'partNumber',
        },
        {
            title: 'Available Quantity',
            dataIndex: 'total_available_quantity',
            render: (text: string, record: any) => {
                const content = (
                    <table className="styled-table" style={{width: '550px'}}>
                        <thead>
                            <tr>
                                <th style={{whiteSpace: 'nowrap' }}>Created At</th>
                                <th style={{whiteSpace: 'nowrap' }}>PO Number</th>
                                <th style={{whiteSpace: 'nowrap' }}>SO Number</th>
                                <th>Purchased Quantity</th>
                                <th>Used (Assigned) Quantity</th>
                                <th>Available Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            { record.partNumberBucketEntries?.map((bucketEntry:any) => 
                                <tr key={bucketEntry.bucketId}>
                                    <td style={{whiteSpace: 'nowrap' }}>{ moment(bucketEntry.createdAt).format('MM/DD/YYYY HH:mm:ss') }</td>
                                    <td style={{whiteSpace: 'nowrap' }}>{bucketEntry.poNumber}</td>
                                    <td style={{whiteSpace: 'nowrap' }}>{bucketEntry.soNumber}</td>
                                    <td>{bucketEntry.purchasedQuantity}</td>
                                    <td>{bucketEntry.purchasedQuantity - bucketEntry.availableQuantity}</td>
                                    <td>{bucketEntry.availableQuantity}</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                );

                if(record.total_available_quantity > 0) {
                    return (
                        <Popover placement="topLeft" key={record.partNumber} content={content} title="PO History">
                            <span style={{ cursor: "pointer", color: "#119FFB", textDecoration: "underline" }}>{record.total_available_quantity}</span>
                        </Popover >
                    )
                } else {
                    return (
                        <span style={{ cursor: "pointer" }}>{record.total_available_quantity}</span>
                    )
                }

            }
        },
    ];


    const orgBucketTableColumn = [
        {
            title: 'Service Plan Description',
            dataIndex: 'packageName',
            render: (text: string) => {
                text = text.replace(/\s*\(.*?\)$/, '');
                if (/Local Plan/gi.test(text)) 
                    {        
                         text = 'Local Plan (WiFi Only)'; 
                    }
    
                return text;
            },
        },
        {
            title: 'Service Plan Part Number',
            dataIndex: 'partNumber',
        },
        {
            title: 'Available Quantity',
            dataIndex: 'total_available_quantity',
            render: (text: string, record: any) => {
                const content = (
                    <table className="styled-table" style={{width: '550px'}}>
                        <thead>
                            <tr>
                                <th style={{whiteSpace: 'nowrap' }}>Transfer Date</th>
                                <th>Transferred From (Org)</th>
                                <th>Transferred In Quantity</th>
                                {/* <th>Transferred To (Org)</th> */}
                                <th>Returned Quantity</th>
                                <th>Used (Assigned) Quantity</th>
                                <th>Available Quantity</th>
                                {
                                    props.currentOrgDetails.orgId != props.context.user.homeOrg.orgId && props.context.user.contextUserOrgIsBillableCustomer &&
                                        <th>Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            { record.partNumberBucketEntries?.map((bucketEntry:any) => 
                                <tr key={bucketEntry.bucketId}>
                                    <td style={{whiteSpace: 'nowrap' }}>{ moment(bucketEntry.createdAt).format('MM/DD/YYYY HH:mm:ss A') }</td>
                                    <td style={{textAlign: 'center', whiteSpace: 'nowrap'}}>{bucketEntry.transferredInFromOrgName}</td>
                                    <td style={{backgroundColor: 'rgba(3, 190, 252, 0.1)', textAlign: 'center'}}>{bucketEntry.transferredInQuantity}</td>
                                    {/* <td style={{backgroundColor: 'rgba(150, 32, 107, 0.2)', textAlign: 'center', whiteSpace: 'nowrap'}}>{bucketEntry.transferredOutToOrgName ? bucketEntry.transferredOutToOrgName : "N/A"}</td> */}
                                    <td style={{backgroundColor: 'rgba(150, 32, 107, 0.1)', textAlign: 'center'}}>{bucketEntry.transferredOutQuantity ? bucketEntry.transferredOutQuantity : "0"}</td>
                                    <td style={{textAlign: 'center'}}>{bucketEntry.transferredInQuantity - bucketEntry.availableQuantity - bucketEntry.transferredOutQuantity}</td>
                                    <td style={{backgroundColor: 'rgba(11, 156, 110, 0.1)', textAlign: 'center' }}>{bucketEntry.availableQuantity }</td>
                                    {
                                        props.currentOrgDetails.orgId !== props.context.user.homeOrg.orgId && props.context.user.contextUserOrgIsBillableCustomer && parseInt(bucketEntry.availableQuantity) !== 0 &&
                                            <td style={{textAlign: 'center' }}>
                                                <Button onClick={() => returnAvailableServicePlans(bucketEntry)} size='small' type="primary">
                                                    Return { bucketEntry.availableQuantity } Service Plan(s)
                                                </Button>
                                            </td>
                                    }
                                    
                                </tr>
                            )}
                        </tbody>

                    </table>
                );

                if(record.total_available_quantity > 0) {
                    return (
                        <Popover placement="topLeft" key={record.partNumber} content={content} title="Transfer History">
                            <span style={{ cursor: "pointer", color: "#119FFB", textDecoration: "underline" }}>{record.total_available_quantity}</span>
                        </Popover >
                    )
                } else {
                    return (
                        <span style={{ cursor: "pointer" }}>{record.total_available_quantity}</span>
                    )
                }

            }
        },
    ];
          
    return (     

        <>

            <Modal title="Entry Warning" visible={isModalVisible} okText="Yes, Add" cancelText="No" okType="danger" onOk={handleOk} onCancel={handleCancel}>
                <span>{ modalMessage }</span><br />
                <span>Are you sure you still want to add this service plan?</span> 
            </Modal>

            <Form
                id="transferServicePlanForm"
                ref={formRef}
                layout="vertical"
                onFinish={onFinishSubmitTransfer}
            >   
                <Row justify="center" gutter={4}>

                    <Form.Item name="toOrgId" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="fromOrgId" >
                        <Input value={props.context.appContext.orgId} type='hidden' />
                    </Form.Item>
                    <Form.Item name="bucketId" >
                        <Input value="" type='hidden' />
                    </Form.Item>
                    <Form.Item name="userConfirmed" >
                        <Input value="" type='hidden' />
                    </Form.Item>

                    {
                        props.currentOrgDetails.orgId !== props.context.appContext.orgId && props.context.user.contextUserOrgIsBillableCustomer && props.context.appContext.orgId !== 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' &&
                            <>
                                <Col style={{ marginTop: '10px' }} xs={16} sm={16} md={16} lg={16} xl={16}>
                                    <Form.Item
                                    label=""
                                        name="partNumberId"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >

                                        <Row style={{width: '%100', marginBottom: '7px'}} justify="space-between">
                                            <Col span={12}>
                                                <span style={{color: 'red'}}> * </span>
                                                <span>Select Service Plan To Transfer</span>
                                            </Col>
                                        </Row>
                                    
                                        <Select
                                            placeholder="Select Service Plan"
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            onChange={(value, e) => updateFormField(value, 'partNumberId')}
                                            filterOption={(input, option) => {
                                                return  option?.children[0]?.props?.children[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}
                                        >
                                        {
                                            props.customerBucketDetails != null &&
                                                POQuickMode ?
                                                Object.keys(props.customerBucketDetails)?.map((itemKey:any) => (
                                                    (props.customerBucketDetails[itemKey]?.total_available_quantity > 0) &&
                                                        <Option key={props.customerBucketDetails[itemKey]?.servicePlanId} value={props.customerBucketDetails[itemKey]?.servicePlanId}>
                                                            <span>{props.customerBucketDetails[itemKey]?.packageName} </span>
                                                            <span style={{ float: 'right' }}>Available Quantity: {props.customerBucketDetails[itemKey]?.total_available_quantity}</span> 
                                                        </Option>
                                                    
                                                ))
                                                :
                                                Object.keys(props.customerBucketDetails)?.map((itemKey: any) => (
                                                    Object.keys(props.customerBucketDetails[itemKey]?.partNumberBucketEntries)?.map((POEntry:any) => (
                                                        (props.customerBucketDetails[itemKey]?.partNumberBucketEntries[POEntry].availableQuantity > 0) &&
                                                            <Option key={props.customerBucketDetails[itemKey]?.partNumberBucketEntries[POEntry].bucketId} value={props.customerBucketDetails[itemKey]?.partNumberBucketEntries[POEntry].bucketId.toString()}>
                                                                <span>{props.customerBucketDetails[itemKey]?.packageName}, PO# {props.customerBucketDetails[itemKey]?.partNumberBucketEntries[POEntry].poNumber}, Available Qty: { props.customerBucketDetails[itemKey]?.partNumberBucketEntries[POEntry].availableQuantity } </span>
                                                            </Option>
                                                    
                                                    ))
                                                ))
                                                    
                                            }
                                        </Select>
                                            
                                    
                                        <Row style={{width: '%100'}}>
                                            <Col style={{ textAlign: 'right' }} span={24}>
                                                <Tooltip placement="top" title={POQuickMode ? "Purchase Order Transfer Mode, in this mode you can transfer service plans from specific PO#." : "Quick Transfer Mode, in this mode the transferred service plan(s) will be automatically selected from the oldest purchase order record."}>
                                                    <Button size='small' type="link" onClick={() => withBetweenPOandQuickMode()}>
                                                        <span>{POQuickMode ? 'Switch to PO# Mode' : 'Switch back to Quick Mode'}
                                                            <InfoCircleOutlined style={{ color: 'orange', cursor: 'pointer', marginLeft: '3px' }} />
                                                        </span>
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                        
                                    </Form.Item>

                                </Col>

                                <Col style={{ marginTop: '10px' }} xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Form.Item 
                                        label="Transfer Quantity"
                                        name="transferredQuantity"
                                        rules={[
                                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired },
                                            { validator: (_, value) => {
                                                if (!Number.isInteger(Number(value))) {
                                                    return Promise.reject('Whole Numbers Only'); 
                                                } 
                                                return Promise.resolve(); 
                                            
                                            }}
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter Quantity"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                
                            

                            <Divider></Divider>
                        </>
                    }

                    {
                        props.context.appContext.orgId === 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' ? 
                            <Col style={{ marginTop: '10px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div style={{ width: '99%', border: "1px #e0e0e0 solid", margin: '0 auto', padding: "4px" }}>
                                    <h3>
                                        <span>Available Service Plans in ({props.currentOrgDetails.name})</span>
                                         {/* Commenting this below checkbox for OPS-6415 */}
                                        {/* <Checkbox 
                                            onClick={toggleZeros}
                                            defaultChecked={showZeros}
                                            style={{ float: 'right' }}
                                        >
                                            <span style={{paddingLeft: "2px"}}>Show Zero Quantities</span>
                                        </Checkbox>  */}
                                    </h3>
                                    <Table
                                        pagination={false}
                                        columns={currentOrgBucketDetails?.some((record: any) => 
                                            record.partNumberBucketEntries?.some((entry: any) => 
                                                entry.transferredInFromOrgId != null
                                            )
                                        ) ? orgBucketTableColumn : customerBucketTableColumn}
                                        dataSource={!showOrgZeros ? currentOrgBucketDetails?.filter((details: any) => details['total_available_quantity'] > 0) : currentOrgBucketDetails}
                                        rowKey={(record) => record.partNumber}
                                        size="small"
                                        locale={{ emptyText: 'No Service Plans' }}
                                    />
                                </div>
                                {props.assetsList.assetMaint.tutorials.tutorialsList.map((tutorial: any) => 
                                    (tutorial.category === "Userguide" && tutorial.id === 20 &&
                                        <div><a href={tutorial.link} target="_blank">Check Here</a><span> to know about our Service Plans</span> </div>
                            ))}
                            </Col>
                        :
                        props.currentOrgDetails.orgId !== props.context.appContext.orgId && props.context.user.contextUserOrgIsBillableCustomer ?
                            <>
                                {
                                    props.customerBucketDetails?.length > 0 &&
                                    <Col style={{ marginTop: '10px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <div style={{ width: '99%', border: "1px #e0e0e0 solid", margin: '0 auto', padding: "4px" }}>
                                            <h3>
                                                <span>Available Service Plans in ({props.currentOrgDetails.name})</span>
                                                {/* Commenting this below checkbox for OPS-6415 */}
                                                {/* <Checkbox
                                                    onClick={toggleOrgZeros}
                                                    defaultChecked={showOrgZeros}
                                                    style={{ float: 'right' }}
                                                >
                                                    <span style={{ paddingLeft: "2px" }}>Show Zero Quantities</span>
                                                </Checkbox> */}
                                            </h3>
                                            <Table
                                                pagination={false}
                                                columns={orgBucketTableColumn}
                                                dataSource={!showOrgZeros ? currentOrgBucketDetails?.filter((details: any) => details['total_available_quantity'] > 0) : currentOrgBucketDetails}
                                                rowKey={(record) => record.partNumber}
                                                size="small"
                                                locale={{ emptyText: 'No Service Plans' }}
                                            />
                                        </div>
                                        {props.assetsList.assetMaint.tutorials.tutorialsList.map((tutorial: any) => 
                                    (tutorial.category === "Userguide" && tutorial.id === 20 &&
                                        <div><a href={tutorial.link} target="_blank">Check Here</a><span> to know about our Service Plans</span> </div>
                            ))}
                                    </Col>
                                }

                                {
                                    props.customerBucketDetails?.length > 0 && props.context.user.contextUserOrgIsBillableCustomer && (props.currentOrgDetails.orgId !== props.context.user.homeOrg.orgId)  &&
                                    <Col style={{ marginTop: '10px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <div style={{ width: '99%', border: "1px #e0e0e0 solid", margin: '0 auto', padding: "4px" }}>
                                            <h3>
                                                    <span>Available Service Plans in ({props.context.appContext.mode !== 'support' ? props.context.user.homeOrg.name : "Support Mode Org" })</span>
                                                {/* Commenting this below checkbox for OPS-6415 */}
                                                {/* <Checkbox 
                                                    onClick={toggleZeros}
                                                    defaultChecked={showZeros}
                                                    style={{ float: 'right' }}
                                                >
                                                    <span style={{paddingLeft: "2px"}}>Show Zero Quantities</span>
                                                </Checkbox>  */}
                                            </h3>
                                            <Table
                                                pagination={false}
                                                columns={customerBucketTableColumn}
                                                dataSource={!showZeros ? props.customerBucketDetails?.filter((details: any) => details['total_available_quantity'] > 0) : props.customerBucketDetails}
                                                rowKey={(record) => record.partNumber}
                                                size="small"
                                            />
                                        </div>
                                        {props.assetsList.assetMaint.tutorials.tutorialsList.map((tutorial: any) => 
                                            (tutorial.category === "Userguide" && tutorial.id === 20 &&
                                             <div>
                                                <a href={tutorial.link} target="_blank">Check Here</a><span> to know about our Service Plans</span> 
                                            </div>
                                        ))}
                                    </Col>
                                }
                            </>
                            :
                            props.customerBucketDetails?.length > 0 &&
                                <Col style={{ marginTop: '10px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div style={{ width: '99%', border: "1px #e0e0e0 solid", margin: '0 auto', padding: "4px" }}>
                                        <h3>
                                            <span>Available Service Plans in ({props.currentOrgDetails.orgId !== props.context.appContext.orgId ? props.context.user.homeOrg.name : props.currentOrgDetails.name})</span>
                                           {/* Commenting this below checkbox for OPS-6415 */}
                                           {/* <Checkbox 
                                                onClick={toggleZeros}
                                                defaultChecked={showZeros}
                                                style={{ float: 'right' }}
                                            >
                                                <span style={{paddingLeft: "2px"}}>Show Zero Quantities</span>
                                            </Checkbox>  */}
                                        </h3>
                                        <Table
                                            pagination={false}
                                            columns={(props.context.user.contextUserOrgIsBillableCustomer && props.currentOrgDetails.orgId === props.context.appContext.orgId) ? customerBucketTableColumn : orgBucketTableColumn}
                                            dataSource={!showOrgZeros ? currentOrgBucketDetails?.filter((details: any) => details['total_available_quantity'] > 0) : currentOrgBucketDetails}
                                            rowKey={(record) => record.partNumber}
                                            size="small"
                                            locale={{ emptyText: 'No Service Plans' }}
                                        />
                                    </div>
                                    {props.assetsList.assetMaint.tutorials.tutorialsList.map((tutorial: any) => 
                                            (tutorial.category === "Userguide" && tutorial.id === 20 &&
                                             <div>
                                                <a href={tutorial.link} target="_blank">Check Here</a><span> to know about our Service Plans</span> 
                                            </div>
                                        ))}
                                </Col>
                                    
                    }
                    

                </Row>

            </Form>

        </>
        
    )
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        assetsList: state.assetsReducer.assetsState.assetsList,
        servicePlansList: state.contractsReducers.contractsState.contracts.servicePlansList,
        customerBucketDetails: state.contractsReducers.contractsState.contracts.customerBucketDetails
    };
};

export default connect(
    mapStateToProps,
    {
        ...actions,
        ...contractActions
    }
)(OrgContractsDetails);