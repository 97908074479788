import Axios from "axios";
import { errorResponse } from "./../../../../utils/apiHelpers/apiHelpers";
import { formatTagsForPosting } from "./../../../../utils/commonHelpers";
import { appConfigs } from "./../../../../utils/configurations";

export function operatorOrgUsersGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
   
) {

    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/getAllUsers", {
                responseType: "json",
                headers: {},
                params: {
                    orgId:null,
                    administered: true,
                    pageSize: 10000
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.users);
                }
                dispatch({ type: "OPR_MAINT_ORG_USERS_GET_SUCCESS", payload: {data: response.data.data.users} });
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
        });
    };
}

export function getAssets(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string,
    orgId: string,
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/operator-assets?page=1&pageSize=9999", {
                responseType: "json",
                headers: {},
                params: {
                    orgId: orgId
                }
            }
        )
        .then(response => {
                let data = (response.data.data.orgAssets == null || (response.data.data.orgAssets?.length || 0) === 0 ) ? [] : response.data.data.orgAssets;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgAssets);
                }
                // let devices:any=[]
                // data.map((tag:any)=>{
                //    devices.push(tag['mastertag']+"`"+tag['serialNumber']);
                // })
                //data = data.map((tag: any) => tag['mastertag']).filter((item: any, i: any, ar: any) => ar.indexOf(item) === i).sort();
    
                dispatch({ type: "GET_ORG_ASSETS_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function oprMaint(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any
) {
    return async (dispatch: any) => {
        record.mastertags = formatTagsForPosting(record.mastertags);
        let url="/ui/api/taylorOperator"
        Axios.post(
            appConfigs.server.URL + url,
            record,
            {
                responseType: "json",
                headers: {}
            }
        )
        .then(response => {
    
            let data = response.data.data.operator == null ? {} : response.data.data.operator;
                
            data['mastertags'] = JSON.parse(data['mastertags']);
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "OPR_MAINT_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
        }
}

export function operatorDelete(
    me: any,
    callbackSuccessFn: any,
    callbackFailureFn: any,
    operatorId:string
    
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/operators/" + operatorId,
            {}
        )
        .then(response => {
                if (callbackSuccessFn != null) {
                    callbackSuccessFn(response);
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFailureFn != null) {
                    callbackFailureFn(error);
                }
            }
        );
    };
}